@import 'appVaribles';
@import 'appCommonStyle';
@import 'appIcons';
@import 'sidebar';
/*----------main header -----------------*/
// .drop{
//   height: 120px!important;
//   line-height: 2;
// }

.main-header {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 99;
  box-shadow: 0px 2px 6px 1px rgba(0, 0, 0, 0.32);

  .top-nav {
    // height: $top-nav-height;
    background-color: $app-primary-color;
    position: relative;
    z-index: 2;

    .web-btn {
      font-size: 16px;
      font-weight: 700;
      color: #ffffff;
      background-color: $app-secondary-color;
      border: none !important;
      border-radius: 0px;

      &:hover {
        background-color: #a71116;
      }
    }

    .navbar {
      padding: 0px;
      background-color: transparent;
      justify-content: initial;
      align-items: self-start !important;
    }

    .navbar-brand {
      padding: 0px 0;
      position: relative;
      z-index: 1;
      color: #eb8b3d;
    }

    .app-title {
      padding: 26px 0;
      // font-family: $font-bold;
      position: absolute;
      font-weight: 900;
      left: 0;
      right: 0;
      top: 0;
      margin: auto;
      text-align: center;

      .navbar-brand {
        padding: 8px 0;
        position: relative;
        z-index: 1;
        color: #eb8b3d;
      }

      .tough-title {
        color: #fff;
        font-size: 22px;
        line-height: 27px;
        margin-bottom: 0px;
        letter-spacing: 0.9px;
      }

      .made-title {
        color: $app-secondary-color;
        font-size: 15px;
        line-height: 18px;
        margin-bottom: 0px;
      }
    }

    // .right-box {
    //   position: relative;
    //   z-index: 1;
    //   padding: 32px 0;
    //   @extend %display-flex;
    // }
    // .user-login {
    //   .nav-link {
    //     padding: 0 16px;
    //     @extend %display-flex;
    //     align-items: center;
    //     &:hover {
    //       i {
    //         color: lighten($app-secondary-color, 25%);
    //       }
    //     }
    //   }
    //   .user-name {
    // font-family: $font-bold;
    //     font-size: 14px;
    //     color: rgb(255, 254, 245);
    //     padding-right: 36px;
    //     display: inline-block;
    //     vertical-align: middle;
    //     letter-spacing: 0.3px;
    //   }
    //   i {
    //     display: inline-block;
    //     vertical-align: middle;
    //     font-size: 30px;
    //     color: $app-secondary-color;
    //   }
    // }
    // .add-cart {
    //   padding: 0 16px;
    //   font-size: 30px;
    //   color: $app-secondary-color;
    //   display: inline-block;
    //   vertical-align: middle;
    //   line-height: normal;
    //   position: relative;
    //   span {
    // font-family: $font-bold;
    //     font-size: 16px;
    //     position: absolute;
    //     top: 9px;
    //     left: 0px;
    //     right: 0;
    //     margin: auto;
    //     text-align: center;
    //   }
    //   &:hover {
    //     i,
    //     span {
    //       color: lighten($app-secondary-color, 25%);
    //     }
    //   }
    // }
    // .custom-dropdown {
    //   background-color: black;
    // }
    // .menu-toggle {
    //   display: none;
    //   padding: 3px 16px;
    //   border: none;
    //   outline: none;
    //   @extend %common-transition;
    //   svg {
    //     stroke: $app-secondary-color;
    //   }
    //   &:hover {
    //     svg {
    //       stroke: lighten($app-primary-color, 20%);
    //     }
    //   }
    // }
  }

  .right-box {
    position: relative;
    z-index: 1;
    padding: 32px 0;
    @extend %display-flex;
  }

  .user-login {
    position: absolute;
    right: 70px !important;
    top: -75px;
    z-index: 3;
    @extend %display-flex;

    .nav-link {
      padding: 0 16px;
      @extend %display-flex;
      align-items: center;

      .account-div {
        &:hover {
          i {
            color: #3f3f3f !important;
          }
        }

        // .icon-user {
        //   margin-top: 3px;
        // }
      }

      // &:hover {
      //   i {
      //     color: lighten($app-secondary-color, 25%);
      //   }
      // }
    }

    .user-name {
      // font-family: $font-bold;
      font-size: 14px;
      font-weight: 900;
      color: #e87335;
      padding-right: 40px;
      display: inline-block;
      vertical-align: middle;
      letter-spacing: 0.3px;
      line-height: 25px;

      .user-code {
        color: $app-secondary-color !important;
        font-size: 18px !important;
      }

      p {
        font-size: 14px !important;
      }
    }

    i {
      display: inline-block;
      font-size: 32px;
      color: $app-secondary-color;
    }
  }

  .add-cart {
    padding: 0 16px;
    font-size: 30px;
    color: $app-secondary-color;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    position: relative;

    span {
      // font-family: $font-bold;
      font-size: 16px;
      font-weight: 900;
      position: absolute;
      top: 9px;
      left: 0px;
      right: 0;
      margin: auto;
      text-align: center;
    }

    &:hover {
      i,
      span {
        color: lighten(#3f3f3f, 25%);
      }
    }

    .nav-label {
      margin-top: 10px;
    }
  }

  .add-cart-user {
    padding: 0 16px;
    font-size: 30px;
    // padding-bottom: 18px;
    color: $app-secondary-color;
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
    position: relative;

    span {
      // font-family: $font-bold;
      font-size: 16px;
      font-weight: 900;
      position: absolute;
      top: 9px;
      left: 0px;
      right: 0;
      margin: auto;
      text-align: center;
    }

    .icon-cart {
      margin-bottom: 17px;
    }

    &:hover {
      i,
      span {
        color: #3f3f3f;
      }
    }
  }

  .right-box-navbar {
    .cart-icon-login-navbar {
      display: none;
    }
  }

  .custom-dropdown {
    background-color: black;
  }

  .menu-toggle {
    display: none;
    padding: 3px 0px;
    border: none;
    outline: none;
    @extend %common-transition;

    svg {
      stroke: $app-secondary-color;
      width: 52px;
      height: 71px;
      margin-top: -18px !important;
      right: 6px !important;
    }

    &:hover {
      svg {
        stroke: lighten($app-secondary-color, 20%);
      }
    }
  }

  .navv {
    display: block;
    height: $links-nav-height;

    background-color: $app-secondary-color;

    @extend %common-transition;

    .menu-close-button {
      display: none;
      padding: 3px 16px;
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 30px;

      i {
        color: $app-primary-color;
      }
    }
  }

  // .pop-over{
  //   .action-button {
  //     display: inline-block;

  .pop {
    .action-button {
      display: inline-block;

      height: 30px;
      width: 30px;
      background: #d3d3d3;
      color: #000;
      font-size: 16px;
      line-height: 30px;
      border-radius: 2px;
      @extend %common-transition;
      margin-right: 10px;
      display: none;
      padding: 15px 20px;

      position: fixed !important;

      // position: absolute;

      // right: 14px;
      // top: 14px;
      // font-size: 30px;
      // i {
      //   color: $app-primary-color;
      // }
      &:hover {
        background-color: #000;
        color: #fff;
      }

      &:last-child {
        margin-right: 0px;
      }
    }
  }

  .app-links-nav {
    display: block;
    height: $links-nav-height;
    background-color: $app-secondary-color;

    @extend %common-transition;

    .menu-close-button {
      display: none;
      padding: 3px 16px;
      position: absolute;
      right: 14px;
      top: 14px;
      font-size: 30px;

      i {
        color: $app-primary-color;
      }
    }

    .okapi {
      // width: 200px;
      width: 60px;
    }

    .nav-item {
      position: relative;

      .btn-secondary.dropdown-toggle {
        &:focus {
          box-shadow: none !important;
        }
      }

      &:focus {
        outline: none;
      }
    }

    .account-div {
      // font-family: $font-normal !important;
    }

    .nav-link {
      position: relative;
      line-height: 50px;
      padding: 0 11px;
      font-size: 12px;
      font-weight: 900;
      color: $app-primary-color;
      text-transform: uppercase;
      @include css3-prefix(transition, color 100ms ease-in-out);

      &.active {
        // background: #;
        color: #fff;
        padding: 0px 20px;

        &:before {
          content: '';
          position: absolute;
          top: -6px;
          left: 50%;
          width: 54px;
          height: 16px;
          margin-left: -31px;
          background: url(../images/nav-linkActive-arrow.svg) no-repeat 0px -5px;
          opacity: 1;
        }
      }

      &:hover:not(.active),
      &:focus:not(.active) {
        color: #000;
      }

      &.active {
        &:before {
          opacity: 1;
        }
      }
    }

    .header-search {
      padding: 14px 0 8px 12px !important;
      display: none !important;
    }

    .header-search-box {
      position: relative;
      margin-bottom: 0px;

      input {
        height: 32px;
        border-radius: 0px;
        min-width: 300px;
        background-color: rgba(29, 85, 50, 0.2);
        font-size: 14px;
        color: #000;
        padding-right: 30px;
        border: none;
        outline: none;
        box-shadow: none;
        border: 1px solid transparent;
        @include css3-prefix(transition, all 100ms ease-in-out);

        &:focus {
          border-color: inherit !important;

          & + i {
            color: #fff;
          }
        }
      }

      ::-webkit-input-placeholder {
        color: #3f3f3f;
      }

      ::-moz-placeholder {
        color: #3f3f3f;
      }

      :-ms-input-placeholder {
        color: #3f3f3f;
      }

      :-moz-placeholder {
        color: #3f3f3f;
      }

      i {
        position: absolute;
        right: -2px;
        top: 46px;
        color: #fff;
        font-size: 17px;
        background: #000 !important;
        padding-top: 15px;
      }
    }
  }

  &.login-header {
    .website-btn {
      margin-top: 9px !important;
    }

    .top-nav {
      height: 150px !important;
    }

    .top-nav .app-title {
      padding-top: 28px;
    }

    .app-links-nav {
      display: none;
    }
  }
}

.nav-container {
  position: relative;
}

/*----------main footer -----------------*/

.main-footer {
  // height: $footer-height;
  background-color: rgb(23, 40, 64);
  position: initial;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 15px 0px;

  .cust-container,
  .hRnZkV {
    @extend %display-flex;
    justify-content: space-between;
  }

  .center-box {
    // font-family: $font-light;
    font-size: 12px;
    color: #fff;
    // padding: 7px 0;
    // position: absolute;
    // text-align: center;
    left: 0;
    right: 0;
    top: 0px;
    margin: 0 auto;
    width: 270px;
    // text-align: center;

    a.logo {
      display: inline-block;
      vertical-align: middle;
      width: 53px;
      margin: 0 9px;
    }
  }

  .footer-link {
    line-height: 15px;
    margin-right: 22px;
    line-height: 20px;
    white-space: normal;

    .terms {
      // font-family: $font-light;
      font-size: 12px;
      line-height: 20px;
      color: #fff;
      text-decoration: underline;
      padding: 0 3px;
      top: -2px;
      position: relative;
      @extend %common-transition;

      &::before {
        content: '';
        position: absolute;
        height: 13px;
        width: 1px;
        right: -5px;
        top: 16px;
        background-color: #fff;
      }

      &:hover {
        color: $app-secondary-color;
        text-decoration: underline;
      }

      // &:hover {
      //   span {
      //     color: $app-secondary-color;
      //   }
      // }
      // &:last-child {
      //   margin-right: 0px;
      // }
    }

    i,
    span {
      @extend %common-transition;
    }

    i {
      font-size: 12px;
      color: $app-primary-color;
    }

    span {
      color: #fff;
      // font-family: $font-light;
      font-size: 12px;
      padding-left: 12px;
      line-height: normal;
    }

    &:hover {
      span {
        color: #000;
      }
    }

    // &:last-child {
    //   margin-right: 0px;
    // }
  }

  nav {
    ul {
      margin: 0px;
    }

    li {
      p {
        // font-family: $font-light;
        font-size: 12px;
        color: #fff;
        text-decoration: underline;
        padding: 0 3px;
        position: relative;
        margin-bottom: 0px;
        @extend %common-transition;

        &::before {
          content: '';
          position: absolute;
          height: 13px;
          width: 1px;
          right: -5px;
          top: 2px;
          background-color: #fff;
        }

        &:hover {
          color: #000;
          cursor: pointer;
          text-decoration: underline;
        }
      }

      &:last-child {
        p {
          &:before {
            display: none;
          }
        }
      }
    }
  }
}

.login-footer {
  .main-footer {
    height: 90px !important;

    .center-box {
      top: 0px !important;
      left: 0px !important;
      right: 0px !important;
      margin: auto;
    }
  }
}

/*----------main container --------------*/
.main-wrapper {
  position: relative;
}

main {
  padding-top: $top-nav-height + $links-nav-height;
  min-height: 100vh;
  padding-bottom: $footer-height;

  &.login-main-page {
    padding-bottom: $footer-height !important;
    padding-top: 100px;
  }
}

/*----------login section----------------*/
.login-section {
  @include calc(min-height, '100vh - #{$top-nav-height} - #{$footer-height}');

  @extend %display-flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  -webkit-background-size: cover !important;
  -moz-background-size: cover !important;
  -o-background-size: cover !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;

  .login-box {
    position: relative;
    width: 631px;
    height: 600px;
    margin-top: 80px;
    border-radius: 0px;
    border: none;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    // background-image: url('../images/login-bg.jpg');
    // background-repeat: no-repeat;
    // background-position: 100.1% 100.5%;
    .apply-label {
      font-size: 16px;
      color: #3f3f3f;
    }

    .bottom-links {
      // position: absolute;
      // bottom: 26px;
      // left: 0px;
      // right: 0px;
      margin: auto;
      // font-family: $font-light;
      font-size: 14px;
      color: #000;
      text-decoration: underline;
      text-align: center;

      a {
        display: inline-block;
        line-height: 25px;
        text-transform: capitalize;
        font-family: 'HelveticaNeue-Bold';
        font-size: 14px;
        border-radius: 0px;
        padding: 0 15px;
        text-align: center;
        background-color: #fff;
        color: #000;
        border: 1px solid #000;
        text-decoration: none;
        @extend %common-transition;

        &:hover {
          background: #000;
          color: #fff;
        }
      }
    }

    .login-form {
      max-width: 318px;
      padding: 0 15px;
      margin: 0 auto;
    }

    .btn-box {
      margin-top: 15px;
      margin-bottom: 30px;
    }

    .forget-link {
      // font-family: $font-light;
      font-size: 14px;
      color: #a2a2a2;
      @extend %common-transition;

      &:hover {
        color: darken(#a2a2a2, 20%);
      }
    }
  }
}

/*--------content-page---------------------*/
.content-page {
  padding-top: 95px;
  padding-bottom: 65px;

  .section-row {
    @extend %display-flex;
    margin-left: -10px;
    margin-right: -10px;

    .section-col {
      padding-left: 10px;
      // padding-right: 10px;
    }
  }

  // .section-header {
  //   margin-bottom: 20px;
  // }
}

/*------------home-section---------------------*/
.home-list-labels {
  margin-bottom: 10px;
}

.view {
  color: #1d5532;
  // font-style: italic;
  width: 140px;
  text-align: end;
}

.with-abs-button {
  position: relative;

  button {
    position: absolute;
    right: 50px;
    top: 17px;
  }
}

.invoice-header {
  margin-top: -50px;
}

.invoice-grid-box {
  .invoice-grid-scroll-container {
    overflow-x: auto;
  }

  .invoice-table {
    width: 100%;

    thead {
      margin-top: -10px;

      th {
        background-color: $app-primary-color;
        height: 40px;
        vertical-align: middle;
        // font-family: $font-bold;
        font-weight: normal;
        font-weight: 900;
        font-size: 14px;
        color: $app-secondary-color;
        border: none;
        padding: 5px 8px;

        &.action-col {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        height: 20px;

        &:hover {
          background-color: #f7f7f7;
        }

        td,
        th {
          border: none;
          // font-family: $font-normal;
          font-size: 14px;
          color: #000;
          height: 30px;
          vertical-align: middle;
          padding: 12px 8px;

          &.action-col {
            padding-top: 0px;
            padding-bottom: 0px;
            text-align: center;
          }

          .react-toggle {
            vertical-align: middle;
          }
        }
      }
    }

    &.with-border {
      border-left: 1px solid #000;
      border-right: 1px solid #000;

      th,
      td {
        border-bottom: 1px solid #000;
      }
    }
  }
}

.home-section {
  .no-promotion {
    margin-top: -130px;
  }

  .section-row {
    flex-wrap: nowrap;

    .section-col {
      margin-bottom: 48px;

      &.left-col {
        max-width: 500px;
        min-width: 500px;

        // margin-top: -130px;
      }

      &.right-col {
        flex: 1;
      }
    }
  }

  .material-section {
    padding: 54px 0;
    @include BannerBackground('../images/material-section-bg', 'jpg');
    background-attachment: fixed;
    width: 100%;
    overflow: hidden;

    h2,
    h4 {
      position: relative;
      z-index: 1;
      // font-family: $font-bold;
      font-weight: 900;
      margin-bottom: 0px;
      text-transform: uppercase;
    }

    h2 {
      font-size: 27px;

      line-height: 30px;
    }

    h4 {
      font-size: 15px;
    }

    .material-links-row {
      @extend %display-flex;
      margin-bottom: 54px;

      .material-box {
        // background-image: url(../images/catalogue-img.png) !important;

        flex: 1;
        height: 250px;
        position: relative;
        outline: none;
        @extend %display-flex;
        flex-direction: column;
        // align-items: center;
        // justify-content: center;

        h2 {
          color: $app-primary-color;
          background: $app-secondary-color;
          margin-top: 25px;
          padding: 10px 15px 0px 14px;
          height: 101px;
          margin-right: 50px;
        }

        h4 {
          color: #000;
        }

        &:before {
          content: '';
          position: absolute;
          left: 0;
          right: 0;
          margin: auto;
          top: 0;
          height: 100%;
          // @include calc(width, '100% - 54px');
          width: 100%;
          // background-color: #d3d3d3;
          display: inline-block;
          // @include css3-prefix(transform, skewX(-16deg));
        }
      }
    }

    .subscribe-box {
      height: 241px;
      position: relative;
      @extend %display-flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 382px 0 66px;
      color: $app-secondary-color;

      &:before {
        content: '';
        position: absolute;
        top: 0px;
        height: 100%;
        width: 200%;
        background-color: #d3d3d3;
        right: 160px;
        // border-radius: 0px 90px 47px 0;
        // @include css3-prefix(transform, skewX(-16deg));
      }

      h2 h4 {
        color: $app-secondary-color;
      }

      h4 {
        color: #000;
      }

      form {
        min-width: 265px;
        position: relative;
      }
    }
  }

  .newslist-wrapper {
    margin-top: 40px;

    .news-item {
      cursor: pointer;
    }
  }

  .promotion {
    margin-bottom: 30px;
  }
}

/*----------news list-------------------------*/
.newslist-wrapper {
  // padding-left: 64px;
  .news-item {
    margin-bottom: 35px;

    span {
      margin-bottom: 5px;
    }

    span,
    p {
      font-size: 14px;
      color: #000;
      display: block;
      line-height: 16px;
    }

    h4 {
      // font-family: $font-bold;
      font-size: 20px;
      font-weight: 900;
      color: #000;
      line-height: 25px;
    }
  }
}

/*------------popup--------------------------*/
.confirm-popup {
  .popup-text {
    text-align: center;
    font-size: 18px;
    padding: 10px 0;
  }
}

/*--------news-addEdit---------------------*/
.news-addEdit {
  .image-uploads {
    .form-group {
      display: inline-block;
      vertical-align: top;
      min-width: 240px;

      img {
        max-width: 100%;
      }

      label {
        display: block;
      }

      input {
        display: block;
        margin-bottom: 10px;
      }

      .thumbnail {
        width: 102px;
        min-width: 102px;
        display: inline-block;
        padding: 4px;
        margin-bottom: 0px;
        line-height: 1.42857143;
        background-color: #fff;
        border: 1px solid #ddd;
        border-radius: 4px;

        &.image-large {
          width: auto;
        }
      }
    }
  }
}

/*---------------grids------------------*/
.news-grid {
  .Title-col {
    min-width: 95px;
  }

  .Content-col {
    min-width: 320px;
  }

  .Thumbnail-col {
    min-width: 200px;
  }

  .ImageUrl-col {
    min-width: 200px;
  }
}

/*--------------marketing section------------*/

.marketing-client-section {
  .marketing-table {
    min-width: 650px;

    .date-col {
      min-width: 100px;
    }
  }
}

.email-link {
  // margin-right: 20px;
  color: blue;
}

/*------------------training-------------*/
.traning-client-section {
  .training-grid {
    min-width: 650px;

    .date-col {
      min-width: 100px;
    }
  }
}

/*-------admin-dashboard---------*/
.section-header {
  span {
    .text {
      margin-bottom: 100px;
      position: relative;
    }
  }

  .input-box {
    margin-top: -8px;
  }
}

.admin-table {
  // width: 1000px;
  // align-items: center;
}

/*------------newsCustomer-section----------*/
.newsCustomer-section {
  .newsbox-container {
    overflow: hidden;
  }

  // .news-row {
  //   margin-right: -112.5px;
  //   margin-left: -112.5px;

  //   .news-col {
  //     padding: 0 112px;
  //   }
  // }

  .news-card {
    border: none;
    margin-bottom: 85px;
    cursor: pointer;

    .card-body {
      padding: 0px;
    }

    .card-img-top {
      margin-bottom: 19px;
    }

    .card-title {
      color: #000;
      // font-family: $font-normal;
      font-size: 16px;
      line-height: 20px;
      margin-bottom: 17px;
      font-weight: 900;
    }

    .card-subtitle {
      // font-family: $font-bold;
      font-size: 24px;
      font-weight: 900;
      color: $app-secondary-color;
      line-height: 29px;
      text-transform: capitalize;
      margin-bottom: 15px;
    }

    .card-text {
      font-size: 16px;
      color: #000;
    }
  }
}

/*------------fullNews-page-------------------*/
.fullNews-page {
  margin-top: -10px;

  .news-banner {
    height: 486px;
    background-color: #eee;
    background: no-repeat;
    background-position: center;
    background-size: cover;
  }

  .news-container {
    max-width: 1246px;
    margin: 0 auto;
    padding: 0 140px;
    position: relative;
    padding-top: 44px;
  }

  .close-button {
    position: absolute;
    right: 130px;
    top: 44px;
  }

  .news-title {
    color: #000;
    // font-family: $font-normal;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 22px;
    font-weight: 900;
  }

  .news-sub-title {
    // font-family: $font-bold;
    font-size: 27px;
    font-weight: 900;
    color: $app-secondary-color;
    line-height: 45px;
    text-transform: capitalize;
    margin-bottom: 45px;
  }

  .news-text-container {
    margin-bottom: 35px;

    p,
    span {
      font-size: 16px;
      color: #000 !important;
      line-height: 33px;
    }

    a {
      span {
        color: rgb(229, 160, 13) !important;
      }
    }
  }
}

/*------------catalogue----------------*/
.catalogue-section {
  .footer-button-box {
    padding-top: 24px;
  }

  .sub-menu {
    padding: 0px;

    li {
      margin-bottom: 6px;

      &:last-child {
        margin-bottom: 0px;
      }
    }
  }

  .accordion {
    .card-body {
      padding: 10px 0px;
    }
  }
}

// .add-icon{
//   position: relative;
// }
.cam-btn {
  color: #1d5532;
}

// .fix-width-card{
//   min-width: 340px;
// }
.catalogue-col {
  margin-bottom: 20px;
}

.catalogue-card {
  border: 1px solid #d3d3d3;
  position: relative;
  border-radius: 0px;
  height: 100%;
  margin-bottom: 20px;

  .app-chk .chk-style {
    background-color: rgba(255, 255, 255, 0.69);
  }

  .app-chk {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;

    label {
      font-size: 14px;
    }
  }

  .card-body {
    border-top: 1px solid #d3d3d3;
    padding: 1.25rem 0.5rem;
    padding-bottom: 0px !important;
  }

  .product-image {
    height: 283px;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain;
    // object-fit: cover;
    // width: 369px;
  }

  .card-row {
    &:last-child {
      margin-bottom: 0px;
    }

    @extend %display-flex;
    margin-bottom: 12px;

    .left-box,
    .right-box {
      flex: 1;
    }

    &.action-row {
      flex-wrap: wrap;

      .left-box {
        width: auto;
        min-width: 100px;
        margin-bottom: 5px;
      }

      .right-box {
        margin-left: auto;
        flex: auto;
        white-space: nowrap;
      }
    }
  }

  .header-text {
    margin-bottom: 22px;

    p {
      margin-bottom: 0px;
    }
  }

  .card-title {
    // font-family: $font-bold;
    font-size: 14px;
    font-weight: 900;
    color: #000;
    margin-bottom: 2px;
  }

  .card-text {
    font-size: 14px;
    color: #000;
    line-height: 16px;

    span {
      display: inline-block;
    }
  }

  .button-box {
    padding-top: 8px;
  }
}

/*------------cart---------------*/
// .po-placeholder{
//  padding: 600px 600px;
// }
.upload-profilephoto {
  .upload-button {
    margin-left: 300px;
  }

  .file-name {
    left: 130px !important;
    position: relative;
    display: block;
    text-align: center;
  }

  .error-file {
    margin-left: 130px;
  }

  .green-title {
    font-size: 16px;
  }
}

.cart-section {
  .grid-header-actions {
    align-items: flex-end;

    .left-box {
      max-width: 597px;
    }
  }

  .app-grid-box {
    .no-product {
      position: absolute;
      margin-top: -100px;
    }

    .app-grid {
      .code-col,
      .price-col {
        min-width: 100px;
      }

      .desc-col {
        min-width: 300px;
      }

      .action-col {
        min-width: 188px;
      }

      tfoot {
        th,
        td {
          padding: 8px 8px;
          color: #000;
          font-size: 14px;
        }

        th {
          // font-family: $font-bold;
          padding-right: 15px;
          font-weight: 900;
        }

        .value-text {
          border: 1px solid #000;
          box-shadow: -1px 0 0 0 #000;
        }
      }
    }
  }

  h3.box-title {
    font-size: 16px;
    // font-family: $font-bold;
    color: $app-secondary-color;
    font-weight: 900;
    text-transform: uppercase;
  }

  .date-select-box {
    text-align: center;
    padding-top: 60px;
    padding-bottom: 88px;

    .select {
      display: inline-block;
      padding: 0 10px;

      select {
        border: none;
        border-bottom: 1px solid #000;
        font-size: 14px;
        // font-family: $font-light;
        color: #000;
        line-height: 38px;
        border-radius: 0px;
      }
    }
  }

  .select-payment-box {
    text-align: center;

    & > h3.box-title {
      margin-bottom: 17px;
    }

    .payment-options {
      .payment-chk {
        display: inline-block;
        padding: 0 17px;

        label {
          display: inline-block;
          position: relative;
          cursor: pointer;
          margin-bottom: 0px;

          &:hover .chk-style,
          input:checked ~ .chk-style {
            border-color: #000;
            background-color: lighten($app-primary-color, 20%);

            h3,
            i {
              color: $app-secondary-color;
            }
          }

          input:checked ~ .chk-style {
            border-color: #000;
            background-color: $app-secondary-color;

            h3,
            i {
              color: $app-primary-color;
            }
          }

          .chk-style,
          h3,
          i {
            @extend %common-transition;
          }

          .chk-style {
            min-width: 260px;
            border: 1px solid #000;
            border-radius: 0px;
            padding: 16px;
            color: $app-primary-color;
            position: relative;
            padding: 9px 0 2px;
          }

          input {
            position: absolute;
            height: 100%;
            width: 100%;
            left: 0;
            top: 0;
            opacity: 0;
            cursor: pointer;
          }

          h3.box-title {
            margin-bottom: 12px;
          }

          i {
            font-size: 85px;
            line-height: 65px;
          }
        }
      }
    }
  }

  .footer-button-box {
    padding-top: 82px;
    padding-bottom: 85px;
  }
}

/*-------------loader----------------------*/
.sweet-loading {
  .css-198xogd {
    border-color: $app-secondary-color $app-secondary-color transparent;
  }
}

.bootstrap-demo .dropdown-trigger {
  border-radius: 0.25rem;
}

.bootstrap-demo .dropdown-trigger > span:after {
  font-size: 12px;
  color: #555;
}

.bootstrap-demo .toggle {
  font: normal normal normal 12px/1 FontAwesome;
  color: #555;
}

.bootstrap-demo .toggle.collapsed::after {
  content: '\f067';
}

.bootstrap-demo .toggle.expanded::after {
  content: '\f068';
}

.bootstrap-demo .root {
  padding: 0px;
  margin: 0px;
}

/*--------------customerAccReg-section---------------------*/
.stepForm-container {
  max-width: 1230px;
  padding: 0 15px;
  margin: 0 auto;
}

.customerAccReg-section,
.standupForm-section {
  overflow: hidden;

  .form-step {
    h4.green-title,
    p {
      font-size: 18px;
      color: #000;
    }

    h2,
    h4,
    strong,
    h6 {
      // font-family: $font-bold;
      font-weight: 900;
    }

    h2 {
      font-size: 34px;
      border-bottom: 1px solid #eee;
      padding-bottom: 20px;
    }

    .was-validated .form-control:invalid,
    .form-control.is-invalid {
      background-position: right 16px center;
    }

    .text-content {
      margin-bottom: 20px;
    }

    .step-common-section {
      // padding: 20px 0;
      // border-bottom: 1px solid #f6f6f6;
      padding: 20px 15px 5px;
      box-shadow: 0px 1px 4px 1px rgba(0, 0, 0, 0.11);
      margin: 17px 0;
    }

    .contact-input {
      // input:first-child {
      //   max-width: 68px;
      // }
    }

    .same-inputs-group {
      background: #eee;
      padding: 8px 8px 0;
      margin-bottom: 10px;

      .form-group {
        margin-bottom: 10px;
      }
    }

    .cell-bot-align {
      @extend %display-flex;
      align-items: flex-end;

      .form-group {
        flex: 1;
      }
    }

    .same-inputs-group {
      .with-add-remove-btns {
        padding-right: 0;
        padding-top: 40px;

        .add-remove-btn {
          position: absolute;
          top: 0;
          right: -8px;
        }
      }
    }
  }

  .step-button-box {
    padding-bottom: 20px;
    @extend %display-flex;

    .right-box {
      margin-left: auto;
    }

    button {
      &.step-btn {
        font-size: 20px;
        padding: 0 30px;
        height: 54px;
        color: black;
        border: 1px solid black;
      }
    }
  }

  .step1 {
    padding: 60px 0px;

    h4 {
      margin-bottom: 40px;
    }

    h3.green-title,
    ol.documents-list {
      color: #000;
    }

    ol.documents-list {
      padding-left: 26px;

      li {
        margin-bottom: 3px;
      }
    }

    .step-button-box {
      button {
        margin: 0 10px;
        text-transform: uppercase;
      }
    }
  }

  .step2 {
    .step2-list {
      list-style: decimal;
      color: black;
      font-size: 18px;
      margin-left: 16px;
    }

    .sub-header {
      margin-bottom: 25px;
      @extend %display-flex;
      justify-content: space-between;

      .reg-info {
        border: 1px solid #000;
        text-align: left;
        padding: 19px 50px;

        h5,
        p {
          margin-bottom: 0px;
          color: #000;
        }

        h5 {
          // font-family: $font-bold;
          font-size: 18px;
          font-weight: 900;
        }

        p {
          font-size: 16px;
        }
      }
    }
  }

  .step3 {
    .Account-Secured-inputs {
      .cust-radio-group {
        margin-bottom: 5px;
      }
    }

    .title-trade {
      margin-top: 20px;
    }
  }

  .step4 {
    h3 {
      margin-bottom: 15px;
      font-size: 22px;
    }

    .documents-list {
      list-style-type: decimal;
      padding-left: 28px;

      li {
        font-size: 16px;
        margin-bottom: 8px;
        color: #353535;
        padding-left: 8px;
      }
    }

    .uploaded-documents-list {
      margin-bottom: 10px;

      li {
        position: relative;
        font-size: 18px;
        color: #353535;
        padding: 9px 47px 9px 14px;

        &:hover {
          background-color: #f4f4f4;
        }

        button {
          position: absolute;
          right: 0px;
          border: none;
          top: 0;
          height: 100%;
        }
      }
    }

    .upload-button {
      display: block;
      margin: 0 auto;
    }
  }

  .step5 {
    h4 {
      color: #000;
      font-size: 16px;
      margin-bottom: 15px;
    }
  }

  .step6 {
    .step-header {
      position: relative;
      padding: 0 180px;
      margin-bottom: 45px;

      .left-box {
        position: absolute;
        left: 0px;
        bottom: 20px;
      }

      .center-box {
        max-width: 690px;
        text-align: center;
        margin: 0 auto;

        img {
          width: 100px;
          margin-bottom: 10px;
        }

        h3 {
          font-size: 22px;
        }
      }
    }

    .list-title {
      font-size: 20px;
      margin-bottom: 15px;
    }

    .turms-list {
      padding-left: 25px;
      margin-bottom: 30px;

      li {
        display: block;
        position: relative;
        padding-left: 40px;
        line-height: 22px;
        margin-bottom: 12px;

        &:before {
          position: absolute;
          left: 0;
          font-size: 14px;
        }

        ul {
          padding-top: 10px;
          padding-left: 0px;

          li {
            padding-left: 50px;
          }
        }
      }

      @for $i from 1 through 30 {
        &.turms#{$i} {
          counter-reset: turmsItem;

          & > li {
            &:before {
              content: '#{$i}.' counters(turmsItem, '.');
              counter-increment: turmsItem;
            }
          }
        }
      }
    }

    .accept-checkbox {
      text-align: center;
      margin-bottom: 28px;
    }
  }

  .step7 {
    h2 {
      font-size: 34px;
      border-bottom: 0;
      color: #000;
    }

    p.step1-text {
      max-width: 500px;
      margin: 0 auto;
      margin-bottom: 40px;
    }
  }
}

/*----------userAccount-section-------------------------------*/

.useraccount-form {
  .inp {
    outline: 0;
    border-width: 0 0 1px;
    border-color: 1px solid #000000;
    padding-left: 0px;
  }
}

.useraccount-button {
  .save-send {
    height: 40px;
    text-align: center;
    background-color: $app-primary-color;
    color: #fff;
    line-height: 40px;
    margin-top: 75.5px;
    width: 390px;
    height: 40px;
    margin-left: 415px;
    padding: 0 10px;
    // font-family: $font-bold;font-weight: 900;
    font-size: 16px;
    text-transform: uppercase;
    border-radius: 6px;
    border: none;

    &:hover,
    &:focus {
      background-color: #000;
      box-shadow: none;
      color: #fff;
    }

    &.full-width {
      width: 100%;
      min-width: auto;
    }
  }
}

/*----------userAccount-section-------------------------------*/
.account-header {
  margin-top: 3.5%;
}

.useraddAccount-section {
  h5.table-note {
    font-size: 14px;
    line-height: 16px;
    max-width: 597px;
    margin-bottom: 18px;
  }

  .user-table-data {
    .account-action {
      text-align: right;
      padding-right: 15px;
      min-width: 150px;
    }

    th.account-action {
      button {
        margin-left: 11px;
      }
    }
  }
}

/*-----------------userAddAccount-page---------------------*/
.account-header {
  margin-top: 3.5%;
}

.useraccount-section {
  .step-common-section {
    box-shadow: none;

    .form-group {
      input {
        border: none;
        border-bottom: 1px solid #000;
        padding-left: 0px;
        font-size: 14px;
      }

      ::-webkit-input-placeholder {
        /* Edge */
        color: #000;
      }

      :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #000;
      }

      ::placeholder {
        color: #000;
      }
    }
  }

  // .with-sidebar .sidebar-content {
  //   max-width: 714px;
  // }
  .content-page {
    padding-right: 180px;
  }

  .btn-box {
    padding-top: 60px;

    button {
      width: 100%;
    }
  }
}

/*--------------Switch-Account-section---------------------*/
.switch-account-header {
  margin: 3.5%;
  // .section-header{
  // .btn{
  // //   margin-left:59%;
  // //   min-width: 100px;
  // position: relative;
  // bottom: 13px;
  // //  margin-bottom: 5px;

  // }
  // }
  h2.with-abs-button {
    position: relative;

    button {
      position: absolute;
      right: 50px;
      top: 12px;
    }
  }
}

.switch-account-table-box {
  font-size: 14px;

  .switch-account-table {
    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
    }

    .code-col {
      padding-left: 80px;
    }

    .accountName-col {
      padding-right: 140px;
    }

    .switch-col {
      padding-right: 0px;
    }
  }
}

/*--------------Open-Orders-section---------------------*/
.openorder-header {
  margin-top: 3.5%;
}

.openorders-table-box {
  // border-color: black !important;

  table {
    border-color: black !important;
  }

  // .table-bordered th,
  // .table-bordered td {
  //   border-top: 1px solid black;
  // }

  // .table th, .table td {
  //     padding: 0.75rem;
  //     vertical-align: top;
  //     // border-top: 1px solid black;
  //     width: 500px;
  // }
  .openorders-table-data {
    min-width: 650px;

    thead {
      color: $app-primary-color;
      background-color: #3f3f3f;
      font-size: 14px;
    }

    tbody {
      font-size: 14px;
      color: #000;
      border-radius: 2px 2px 2px 2px;
    }

    .order-col {
      padding-left: 30px;
    }
  }
}

/*----------back-order-------------------------*/
.backorder-header {
  margin-top: 3.5%;

  .out-of-stock-header {
    margin-top: 4%;
  }
}

// Invoices
.invoice-table-box {
  // border-color: black !important;
  table {
    border-color: black !important;
  }

  .table-bordered th,
  .table-bordered td {
    border: 1px solid black;
  }
}

.table-bordered th,
.table-bordered td {
  border-top: 1px solid #3f3f3f !important;
  border: none;
}

/*--------------Stock-Sheets-section---------------------*/

.stocksheet-section {
  .act-col {
    padding-right: 10px;
  }

  .stock-header {
    margin-top: 3.5%;
  }

  h5.table-note {
    font-size: 14px;
    line-height: 16px;
    max-width: 597px;
    margin-bottom: 18px;
  }

  .stocksheet-table-box {
    .stocksheet-table-data {
      min-width: 640px;

      .table-bordered th,
      .table-bordered td {
        border: 1px solid black;
      }

      thead {
        color: $app-secondary-color;
        background-color: $app-primary-color;
        font-size: 14px;
      }

      .action-col {
        padding-right: 70px;
      }

      .act-button {
        margin-left: 8px;
        top: 432px;
        width: 30px;
        height: 30px;
        background: #d3d3d3 0% 0% no-repeat padding-box;
        border-radius: 2px;
        opacity: 1;
        display: inline-block;
      }

      tbody {
        font-size: 14px;
        color: #000;
        border-radius: 6px 2px 2px 2px;

        .stock-button {
          margin-right: -15%;
        }

        .stock-action {
          margin-right: 0;
        }

        th.stock-action {
          padding-right: 0px;

          button {
            top: 497px;
            left: 1469px;
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }
}

.act-col {
  padding-left: 50px;
}

/*-----------myaccount-section-------------------*/
.myaccount-header {
  margin-top: 3%;
}

.myaccount-text {
  font-size: 20px;
  font-weight: 400;
}

span.home-orders {
  font-size: 22px !important;
  // font-family: $font-bold !important;
  font-weight: 900;
}

.balance {
  font-size: 14px;
}

strong {
  // font-family: $font-bold !important;
  font-weight: 900;
}

.myaccount-table-box {
  // border-color: black;
  .myaccount-table {
    font-size: 14px;

    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
    }

    .current-col {
      padding-left: 60px;
    }

    .days-col {
      margin-right: 20px;
    }
  }
}

.myact-table {
  border-color: black;

  .summary {
    border-right: 1px solid #3f3f3f !important;
  }
}

/*-------------stocksheetscreate----------------------*/

.stocksheets-create-header {
  .stocksheet-table {
    margin-top: 7.3%;
  }

  margin-top: 3.5%;
}

.stocksheets-create-placeholder {
  margin-top: 20%;

  .align-save-placeholder {
    padding-left: 45px;

    .file-icon {
      color: #3f3f3f;
      position: absolute;
      left: 16px;
      margin-top: 8px;
    }

    top: 255px;
    left: 1397px;
    height: 14px;
    opacity: 1;

    input {
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 22px;
      font-size: 14px;
      display: inline;
      width: 65%;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #000;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000;
    }

    ::placeholder {
      color: #000;
    }
  }

  .align-save-placeholder {
    display: inline;

    .save {
      top: 235px;
      color: white;
      left: 1675px;
      width: 99px;
      height: 40px;
      background: #1d5532 0% 0% no-repeat padding-box;
      border-radius: 6px;
      opacity: 1;
      display: inline;
      float: inline-end;
    }
  }
}

.stockcreate-table-box {
  margin-top: 55px;

  .stockcreate-table-data {
    max-width: 400px;
    width: 94%;

    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
      font-size: 14px;
    }

    tbody {
      font-size: 14px;
      color: #000;
      border-radius: 2px 2px 2px 2px;
    }
  }
}

/*----------standupform-section-------------------------------*/
.standupform-header {
  margin-top: -5%;
}

/*--------------order-section------------------*/
.order-printer {
  right: 0;
}

.order-value-text {
  font-size: 14px;
}

.order-section {
  tfoot {
    th,
    td {
      padding: 8px 8px;
      color: #000;
      font-size: 14px;
    }

    th {
      // font-family: $font-bold;
      padding-right: 15px;
      font-weight: 900;
    }

    .order-value-text {
      border: 1px solid #000;
      box-shadow: -1px 0 0 0 #000;
    }
  }

  .order-header {
    .order-text {
      font-size: 14px;
    }

    .order-middle {
      font-size: 30px;
      color: $app-secondary-color;
    }

    .order-right-text {
      font-size: 14px;
    }
  }
}

/*-----------rep client contact history-------------------*/
.rep-client-history-header {
  margin: 3.5%;

  .contact-message {
    z-index: 1;
    color: black;
  }

  .message-textarea {
    background: #1d553233 0% 0% no-repeat padding-box;
  }

  .rep-client-history-save {
    margin-top: 2.5%;
    top: 500px;
    left: 1468px;
    width: 99px;
    height: 40px;
    background: var(--unnamed-color-1d5532) 0% 0% no-repeat padding-box;
    background: #1d5532 0% 0% no-repeat padding-box;
    border-radius: 6px;
    opacity: 1;
    float: right;
    color: white;
  }
}

.rep-client-history-table-box {
  margin-top: 5.5%;
  font-size: 14px;

  .rep-client-history-table {
    thead {
      color: $app-secondary-color;
      background-color: $app-primary-color;
    }

    .code-col {
      padding-left: 80px;
    }

    .accountName-col {
      padding-right: 120px;
    }

    .message-col {
      padding-right: 190px;
    }

    .view-col {
      padding-right: 20px;
    }
  }
}

.date-filter {
  text-align: center;
  display: flex;
  flex-direction: column;
}

/*----------report-section-------------------------------*/
.report-section {
  .report-header {
    margin-top: 3.5%;

    .from-date-input {
      margin-left: 22%;
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 0px;
      font-size: 14px;
    }

    .to-date-input {
      margin-left: 60%;
      border: none;
      border-bottom: 1px solid #000;
      padding-left: 0px;
      font-size: 14px;
    }

    ::-webkit-input-placeholder {
      /* Edge */
      color: #000;
    }

    :-ms-input-placeholder {
      /* Internet Explorer 10-11 */
      color: #000;
    }

    ::placeholder {
      color: #000;
    }
  }

  .customer-report {
    margin-left: 10%;
    margin-top: 15%;

    .rep-customer-report {
      display: inline;
      margin-left: 10%;
    }

    .revenue-report {
      display: inline;
      margin-left: 10%;
    }

    .orders-report {
      display: inline;
      margin-left: 10%;
    }
  }
}

/*-----------print products-------------------*/

.print-product-card {
  border: 1px solid #000;
  margin-top: 3%;
  position: relative;
  border-radius: 0px;
  margin-bottom: 20px;

  .app-chk .chk-style {
    background-color: rgba(255, 255, 255, 0.69);
  }

  .app-chk {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 1;
  }

  .card-body {
    border-top: 1px solid #000;
  }

  .print-product-image {
    height: 213px;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    -webkit-background-size: 100% 100%;
    -moz-background-size: 100% 100%;
    -o-background-size: 100% 100%;
    background-size: 100% 100%;
  }

  .card-row {
    &:last-child {
      margin-bottom: 0px;
    }

    @extend %display-flex;
    margin-bottom: 22px;

    .left-box,
    .right-box {
      flex: 1;
    }

    &.action-row {
      flex-wrap: wrap;

      .left-box {
        width: auto;
        min-width: 60px;
        margin-bottom: 5px;
      }

      .right-box {
        margin-left: auto;
        flex: auto;
        white-space: nowrap;
      }
    }
  }
}

page[size='A4'] {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;

  .card-body {
    border-top: 1px solid #000;
  }
}

page {
  background: white;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
}

page[size='A4'][layout='landscape'] {
  width: 29.7cm;
  height: 21cm;
}

/*----------standformagreement-section-------------------------------*/
.r-text {
  color: #000;
}

.agreement-header {
  margin-top: -5%;
}

.green-title-agreement {
  color: $app-primary-color;
}

.black-title-agreement {
  color: #000;
}

.standform-agreement {
  color: #000;
  font-size: 14px;

  .r-text {
    color: #000;
    font-size: 14px;

    .submit-formagreement {
      button {
        float: right;
      }
    }
  }

  .agreement-input {
    box-shadow: none;
    border: none;
    border-bottom: 1px solid #000;
    padding-left: 0px;
    font-size: 14px;
  }
}

.sigPad {
  width: 100%;
  height: 300px;
}

.AccountNavActive,
// .active {
//   font-size: 25px;
//   display: flex;
//   justify-content: space-between;

  .account-icon {
  visibility: visible;
}
// }

.account-icon {
  visibility: hidden;
}

/*----------------product-details-view----------------*/
.product-details-view {
  display: flex;
  flex-wrap: wrap;

  .product-image-wrapper {
    width: 40% !important;
  }

  .product-details {
    width: 60% !important;

    .app-grid-box {
      margin-top: 33px;

      td {
        border-bottom: 1px solid #3f3f3f !important;
      }

      .quantity {
        border-right: 1px solid #3f3f3f;
      }

      .location {
        font-weight: 700;
        text-transform: uppercase;
      }

      h2 {
        font-size: 22px;
        font-weight: 700;
        color: $app-secondary-color;
        text-transform: uppercase;
        margin-bottom: 12px;
      }
    }
  }

  .product-image-wrapper {
    .note {
      p {
        font-size: 16px;
        font-weight: 400;
        color: #000000;
      }
    }

    .warranty {
      font-size: 18px;
      font-weight: 700;
      text-transform: uppercase;
      color: $app-secondary-color;
      margin-bottom: 59px;
    }

    .product-promotion {
      border: 1px solid #000000;
      background-color: $app-secondary-color;
      width: 100%;
      text-align: center;
      // height: 60px;

      label {
        color: $app-primary-color;
        font-size: 22px;
        font-weight: 700;
        margin-bottom: 0px;
        padding: 13.5px 0px;
      }
    }

    // .list-details {
    //   margin-top: 25px;

    //   .row {
    //     div.list-price {
    //       .col-1 {
    //         font-size: 18px;
    //         font-weight: 700;
    //         color: #b2b2b2;
    //         margin-bottom: 10px;
    //       }

    //       .col-2 {
    //         font-size: 18px;
    //         font-weight: 700;
    //         color: $app-secondary-color;
    //         margin-bottom: 10px;
    //       }
    //     }

    //     div.net-price {
    //       .col-1 {
    //         font-size: 20px;
    //         font-weight: 700;
    //         color: #b2b2b2;
    //         margin-bottom: 10px;
    //       }

    //       .col-2 {
    //         font-size: 18px;
    //         font-weight: 700;
    //         color: $app-secondary-color;
    //         margin-bottom: 10px;

    //       }

    //       .net {
    //         color: #000000 !important;
    //       }
    //     }
    //   }
    // }

    .product-image {
      height: 590px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: contain;
      border: 1px solid #000;
    }
  }

  .product-details {
    padding-left: 40px !important;

    h3.productname {
      font-size: 30px;
      line-height: 36px;
      color: #000;
      font-weight: 400 !important;
      margin-bottom: 36px;
    }

    .detail-wrapper.code {
      font-size: 20px;
      font-weight: 700;
      color: $app-secondary-color;
    }

    .product-summary-details {
      margin: 0px;

      .col {
        padding: 0px;
      }

      .cam-btn {
        i {
          color: #fff;
        }
      }

      .detail-wrapper {
        margin-bottom: 40px;

        h5,
        p {
          line-height: normal;
          font-size: 14px;
        }

        h5 {
          // font-family: $font-bold;
          margin-bottom: 3px;
          font-weight: 900;
        }

        p {
          margin-bottom: 0px;
        }
      }
    }

    .product-quantity {
      margin-bottom: 60px;
    }
  }

  .product-description {
    margin-bottom: 50px;

    p {
      font-size: 16px;
      color: #000;
      line-height: 16px;
      margin-bottom: 0px;
    }
  }

  .product-more-detail {
    padding-top: 58px;
  }

  h4.products-title {
    border-bottom: 3px solid #3f3f3f;
    font-size: 22px;
    // font-family: $font-bold;
    color: $app-secondary-color;
    font-weight: 900;
    margin-bottom: 16px;
  }

  .no-font {
    font-size: 14px;
  }
}

/*------------*/
.useraccount-section.useraccountadd-section
  .access-control-section
  .form-group {
  margin-bottom: 22px;
}

.useraccount-section.useraccountadd-section
  .access-control-section
  .app-chk
  .chk-text {
  font-size: 20px;
}

.useraccount-section.useraccountadd-section
  .access-control-section
  .clearfix
  .app-chk
  .chk-text {
  font-size: 16px;
  color: #161616;
}

.useraccount-section.useraccountadd-section .btn-box {
  padding-top: 22px;
  padding-bottom: 20px;

  button {
    width: 200px;
    margin-right: 15px;
  }
}

.css-1pahdxg-control {
  border-color: #1d5532 !important;
  box-shadow: 0 0 0 1px #1d5532 !important;
}

.requiredClass {
  font-weight: 900;
  color: red;
}

.pop-over {
  text-transform: uppercase;
  background: #fff;
}

.popover {
  max-width: 600px;
  padding: 20px 20px;
  background-color: #fff;
  left: 5% !important;
  border-radius: 0rem !important;
}

.submenu-cms {
  .popover {
    left: -1% !important;
    max-width: 250px;
  }
}

.popover-body {
  color: $app-secondary-color;
  text-transform: uppercase;
  // font-style: italic;
  font-weight: bold;
  text-align: left;
}

.popover .arrow {
  border-top-color: #1d5532;
  left: 140px !important;
  width: 100px;
  color: #1d5532 0% 0% no-repeat padding-box;
  position: fixed;
}

.arrow::after {
  color: #1d5532;
}

.popover .tooltip {
  color: #1d5532;
}

.remove-style {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: #000;
}

.remove-style:hover {
  padding: 0;
  margin: 0;
  border: none;
  background: none;
  color: black;
}

.change-pointer {
  cursor: pointer;
}

.change-pointer:hover {
  cursor: pointer;
  color: #000;
}

//  Add category button
.add-categorybtn {
  font-size: 11px;
}

.add-subscribersbtn {
  font-size: 11px;
}

// Modal in home.js

.modal-dialog {
  width: 100%;
  // margin: 0rem!important;
}

// .main-header.login-header .top-nav .navbar {
//   padding: 15px 0px !important;
// }
.nav-Image-width.row {
  padding: 10px 0;
}

/*-----------responsive-------------------*/
@import 'responsive.scss';

// product print
main.pb-0 {
  .printProduct-section {
    .main-product-print-footer {
      position: unset !important;
    }
  }

  .login-footer .main-footer .center-box {
    position: unset !important;
    margin: 0 !important;
  }
}

.app-grid-box .app-grid.with-border th.status-col {
  width: 200px !important;

  @media all and (max-width: 767px) {
    width: unset !important;
  }
}

.red-color {
  background-color: red;
}

.break-word {
  word-break: break-word;
}

// .bghjHX {
//   margin: 20px 0px 10px 0px !important
// }

.promotion-border {
  border-bottom: 3px solid #3f3f3f;
}

.carousel-text {
  font-size: 14px;
}

.carousel-text.text-danger,
.carousel-text.text-muted {
  font-size: 14px;
}

.kVtMtA {
  border-radius: 0% !important;
  box-shadow: 0 0 5px 0 #3f3f3f;
  font-size: 10px;
  opacity: 1.6 !important;
  left: 0px !important;
  right: initial;
  background: #000 !important;
}

.kVtMtA::before {
  border-top: 2px solid #fff !important;
  border-right: 2px solid #fff !important;
}

.bhRnqO {
  border-radius: 0% !important;
  box-shadow: 0 0 5px 0 #3f3f3f;
  font-size: 10px;
  opacity: 1.6 !important;
  right: 0px !important;
  left: initial;
  background: #000 !important;
}

.bhRnqO::before {
  border-top: 2px solid #fff !important;
  border-right: 2px solid #fff !important;
}

.react-autosuggest__suggestions-container {
  margin-top: 5px;
  background-color: #d3d3d3;
  max-height: 200px;
  overflow-y: scroll;
}

.itemStyle {
  border-bottom: 1px solid #ddd;
}

.react-autosuggest__input {
  padding-left: 15px;
  color: #fff !important;
}

.red-background {
  background-color: #eb8b3d;
}

.grey-background {
  background-color: #d3d3d3;
}

.login-span {
  line-height: 32px !important;
}

.login-section {
  .login-box {
    height: auto;
    padding-bottom: 20px;
    width: 570px;
    background-size: 40px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);

    .section-header {
      height: 60px;
      padding: 16px 0px;
      background-size: 80px;
      background: $app-secondary-color;

      span {
        line-height: 45px;
        padding-right: 0px;
        color: #fff;
      }
    }

    .forget-link {
      position: static;
    }
  }
}

.login-section {
  .section-header-without-bg {
    height: 60px;
    padding: 16px 0px;
    background-size: 80px;

    span {
      line-height: 45px;
      font-size: 16px;
      padding-right: 0px;
    }
  }
}

.accordion .card-body {
  max-height: 300px;
  overflow-y: auto !important;
}

.accordion .card-body::-webkit-scrollbar {
  width: 10px;
}

.accordion .card-body::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  // border-radius: 10px;
  border: 1px solid #707070 !important;
}

.accordion .card-body::-webkit-scrollbar-thumb {
  // border-radius: 10px;
  background-color: #3f3f3f;
}

.section-header {
  height: 40px;
  // padding: 8px 28px 0 28px;
  background-size: 80px;

  span {
    line-height: 33px;
    padding-right: 0px;
    border-bottom: 3px solid #3f3f3f;
  }
}

.promotion-grid {
  .promotion-img {
    border: 1px solid #d3d3d3;
    object-fit: contain;
  }
}

.account-div,
.add-cart-user {
  &:hover {
    .nav-label {
      color: #3f3f3f !important;
    }
  }
}

.nav-label {
  font-size: 14px;
  font-weight: 500 !important;
  line-height: 0px;
  text-transform: capitalize;
  color: $app-secondary-color;

  // &:hover {
  //   color: lighten($app-secondary-color, 25%);
  // }
}

.header-search {
  padding: 21px 0 8px 12px !important;
  width: 430px;
}

.header-search-box {
  position: relative;
  margin-bottom: 0px;

  input {
    height: 50px;
    border-radius: 0px;
    min-width: 430px;
    background-color: transparent;
    font-size: 16px;
    color: #000 !important;
    padding: 14px 16px 15px 16px;
    border: none;
    outline: none;
    box-shadow: none;
    border: 2px solid #b2b2b2;
    @include css3-prefix(transition, all 100ms ease-in-out);

    &:focus {
      // border-color: $app-primary-color;

      & + i {
        color: #fff;
      }
    }
  }

  ::placeholder {
    color: #b2b2b2 !important;
  }

  i {
    position: absolute;
    right: 0px;
    top: 0px;
    padding: 16px 0px 0px 18px;
    color: #fff;
    font-size: 17px;
    background: #eb8b3d;
    height: 50px;
    width: 50px;
    cursor: pointer;
  }
}

.search-input {
  width: 100%;
  padding: 5px 10px;
}

.search-toggle {
  background-color: #d3d3d3;
  position: absolute;
  top: 2px;
  width: 175px;
  height: 46px;
  right: 38px;
  border-left: 2px solid #b2b2b2;
  font-size: 14px;

  button {
    color: #3f3f3f;
    text-align: left;
  }

  img {
    margin-left: 20px;
    padding-bottom: 5px;
    position: absolute;
    right: 24px;
    top: 13px;
    transform: rotate(90deg);
  }

  .btn-secondary:focus,
  .btn-secondary.focus,
  .btn-secondary:hover,
  .btn-secondary:not(:disabled):not(.disabled):active,
  .btn-secondary:not(:disabled):not(.disabled).active,
  .show > .btn-secondary.dropdown-toggle {
    box-shadow: none !important;
    color: #3f3f3f;
  }

  .dropdown-item:hover,
  .dropdown-item:focus,
  .dropdown-item.active,
  .dropdown-item:active {
    color: $app-secondary-color !important;
    background: transparent !important;
  }
}

.cart-number {
  input {
    padding: 0px 5px !important;
    border-radius: 2px !important;
    background-color: #d3d3d3 !important;
    width: 50px !important;
    border: 0px !important;
  }
}

.up-down-btn {
  background: #3f3f3f !important;
}

.down-btn {
  transform: rotate(180deg);
}

.account-store {
  margin-top: 30px;

  h4 {
    font-size: 16px;
    color: #000000;
    font-weight: 400;
  }

  label {
    color: $app-secondary-color;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
  }

  .btn {
    background: $app-secondary-color;
    font-weight: 700;
    font-size: 16px;
    text-transform: uppercase;
    padding: 7px 15px;
    color: $app-primary-color;
    border-radius: 0px;
  }
}

.account-table {
  text-align: end;

  td {
    border: 1px solid #3f3f3f !important;
  }
}

.discount-table {
  min-width: 568px !important;
  width: 568px;
}

.invoice-filter {
  div {
    margin-right: 10px;
  }

  input {
    border: 1px solid #000000;
    height: 40px;
    width: 146px;
  }
}

.invoice-search {
  margin-right: 0px;
  display: flex;

  input {
    padding: 0px 8px;
    min-width: 154px;
  }
}

.news-logo {
  bottom: 50px;
  width: 100%;
  background: linear-gradient(90deg, rgba(255, 255, 255, 0), white);
  padding: 10px 30px;
}

.product-cat-div {
  margin-bottom: 33px;

  div {
    margin-bottom: 0px;

    label {
      font-size: 14px;
      font-weight: 900;
      color: #000;
      padding-right: 10px;
    }

    span {
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }

    .number {
      text-decoration: underline;
    }

    .details {
      font-size: 14px;
      font-weight: 400;
      color: #000;
    }

    .price {
      font-size: 27px;
      font-weight: 700;
    }
  }
}

.prducts-btns {
  margin-top: 33px;

  .btn {
    margin-right: 35px;
    padding: 0px 25px;
  }
}

.admin-dashboard {
  .section-header span {
    // font-family: $font-bold;
    font-size: 22px;
    font-weight: 900;
  }
}

.kVtMtA,
.bhRnqO {
  z-index: 1 !important;
}

.dropdown-toggle::after {
  content: '';
  background-image: url(../images/chevron-down.svg) no-repeat;
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  transform: rotate(270deg);
  border: none !important;
  font-weight: 600;
  // margin-left: 15px;
  // height: 32px;
}

.forgot-div {
  height: 130px;
}

.brand-img {
  cursor: pointer;
  height: 65px;
  width: 150px;
}

.nav-links {
  .nav-item {
    // font-family: $font-bold;
    font-weight: 900;
  }
}

input[type='number']::placeholder {
  color: #b2b2b2 !important;
}

.grid-price {
  span {
    font-size: 16px !important;
  }
}

.carousel {
  .dynamic-img {
    object-fit: contain;
  }

  .static-img {
    object-fit: contain;
  }

  img {
    border: 1px solid #d3d3d3;
  }
}

.select-option {
  // padding-top: 16px;
  color: $app-secondary-color !important;
  font-size: 22px !important;
}

// .logout-dropdown.new-dropdown {
//   left: -147px !important;
// }

.new-dropdown {
  padding-top: 20px !important;
  background: #f0f0f0 !important;

  .dropdown-item {
    background: #f0f0f0 !important;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: -3%;
    width: 54px;
    height: 16px;
    margin-left: -31px;
    background: url(../images/nav-linkActive-arrow-red.svg) no-repeat 0px -5px;
    opacity: 1;
  }
}

.react-autosuggest__suggestions-container {
  margin-top: 0px !important;
}

.react-autosuggest__suggestions-list {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    top: 0px;
    right: calc(50% - 54px);
    width: 54px;
    height: 16px;
    margin-left: -31px;
    background: url(../images/nav-linkActive-arrow-red.svg) no-repeat 0px -5px;
    opacity: 1;
  }

  .react-autosuggest__suggestion .react-autosuggest__suggestion--first {
    background: #f0f0f0;
  }
}

.radio-code {
  padding: 20px 0px;
}

.custom-loading {
  background: #f0f0f0 !important;
  border-radius: 5px;
}

.navbar .container {
  justify-content: normal !important;
}

.promotion-row {
  margin-left: -7px !important;
}

.home-second-col {
  padding-left: 40px !important;
}

.pradio-code {
  padding-bottom: 10px;
}

.promo {
  text-transform: uppercase;
  color: $app-secondary-color !important;
  font-weight: 700;
}

.react-autosuggest__suggestion {
  background: #f0f0f0 !important;
}

.react-autosuggest__suggestion--first {
  background: #d3d3d3 !important;
}

// Admin Dashboard
.admin-nav-one {
  background: #82d9d4 !important;
  color: #000 !important;

  .nav-link {
    color: #000 !important;
  }

  .module-type {
    .nav-link {
      color: rgb(23, 40, 64) !important;
    }
  }
}

.admin-nav-two {
  background: $app-secondary-color !important;

  .nav-link {
    color: #d3d3d3 !important;
  }

  .module-type {
    .nav-link {
      color: rgb(23, 40, 64) !important;
    }
  }
}

.admin-drop-btn {
  height: 50px;
}

.dropdown-menu {
  .dropdown-item {
    a.nav-link {
      font-size: 16px !important;
      text-transform: initial !important;
      color: #fff !important;
      line-height: 24px !important;
      font-weight: 700 !important;

      &:hover {
        color: #000 !important;
      }
    }

    &:hover {
      a.nav-link {
        color: #000 !important;
      }
    }
  }
}

.admin-dropdown {
  top: -27px !important;
  border: none !important;
  background: #000 !important;

  .dropdown-item {
    color: $app-primary-color !important;
    font-weight: 700 !important;

    &:hover,
    &:focus {
      color: #000 !important;
    }
  }
}

.admin-dropdown-two {
  top: -27px !important;
  border: none !important;
  background: $app-secondary-color !important;

  .dropdown-item {
    color: $app-primary-color !important;
    font-weight: 700 !important;

    &:hover,
    &:focus {
      color: #000 !important;
    }
  }
}

.nav-container {
  .cust-container {
    height: 50px;
  }
}

.dropdown-item {
  &:active {
    background-color: #c1c1c1 !important;
  }
}

.bqyzQp,
.bghjHX,
.kPsAqE,
.fpzZVj {
  height: 100%;
  align-items: center;
}

// Dynamic Menu
.dynamic-menu {
  .dynamic-dropdown {
    background: #d3d3d3;
    color: #3f3f3f;
  }

  h3 {
    color: #3f3f3f;
    text-transform: uppercase;
    text-decoration: underline;
    font-size: 18px;
    font-weight: 700;
  }

  h4 {
    color: #3f3f3f;
    font-weight: 400;
    font-size: 16px;
    margin-right: 10px;
    width: 150px;
    min-width: 150px;
    align-self: center;
    margin-bottom: 0px;
  }

  .dropdown-toggle {
    border: 2px solid #b2b2b2 !important;
    background-color: #d3d3d3;
    color: #3f3f3f !important;
    padding: 4px 6px 1px 9px !important;
    border-radius: 0px;

    &:focus,
    &:active {
      box-shadow: none !important;
      background-color: #d3d3d3;
      color: #3f3f3f !important;
    }

    .dropdown-item {
      &:hover {
        background-color: #3f3f3f !important;
      }
    }
  }

  .toggle-icon {
    transform: rotate(90deg);
    margin: 0px 7px;
  }
}

.banner-toggle {
  .btn-secondary {
    color: #000 !important;
    background: transparent !important;
    border-color: 1px solid #ced4da !important;

    &:focus {
      color: #000 !important;
      background: transparent !important;
      border-color: 1px solid #ced4da !important;
      outline: none;
      box-shadow: none !important;
    }
  }

  .dropdown {
    .dropdown-menu {
      .dropdown-item:hover {
        &:hover,
        &:focus,
        &:active {
          background-color: $app-secondary-color !important;
        }
      }
    }
  }

  img {
    transform: rotate(90deg);
    margin-left: 5px;
  }
}

.promotion-carousel {
  .bghjHX {
    margin: 0px 80px !important;
  }
}

.marketing-items-section.catalogue-section {
  margin-top: 25px !important;
}

.marketing-items-section {
  margin-top: 75px;
}

.sub-lists {
  .bghjHX {
    padding-top: 5px !important;
  }

  .bVoXXg {
    div {
      &:after {
        // content: "|";
        // padding-left: 35px;
      }
    }
  }
}

.electric-supply {
  font-size: 17px;
}

.asset-sign {
  border-top: 1px solid #eee;
}

.deed-suretyship {
  ul,
  ol {
    li {
      list-style-type: none !important;
      padding: 5px 0px;
    }
  }
}

.stock-note {
  font-size: 16px;
  margin-bottom: 20px;
}

// admin dropdown isssue css start here
.Dropdown_dropdown__2JICB .Dropdown_menu-left__2Ajlc {
  top: calc(100% + 4px);
  right: 0px;
  left: 0;
  min-width: 225px !important;
}

// date picker css start here
.react-datepicker__close-icon {
  padding: 0px 6px 10px 0px !important;
}

.react-datepicker-popper {
  z-index: 999 !important;
}

.list-style-type {
  list-style-type: none;
}

.accordion__item {
  margin-bottom: 1.25rem;
  border-bottom: 0.063rem solid #000000;
}

.scroll-data {
  margin-bottom: 1.5rem;
  max-height: 22.313rem;
  z-index: 999;
  transition: 0.5s ease-in-out !important;
  overflow-x: hidden;
  overflow-y: auto;
  max-width: calc(100% - 12px);
  padding-right: 10px;

  &::-webkit-scrollbar {
    width: 10px;
    height: 5px;
    background-color: #d3d3d3;
    border-radius: 5px;

    & ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
      // background-color: #3f3f3f;
      border-radius: 10px;
    }
  }

  &::-webkit-scrollbar-thumb {
    box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #3f3f3f;
  }
}

.font-size {
  font-size: 18px;
}

.common-14 {
  font-size: 18px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type='number'] {
  -moz-appearance: textfield; /* Firefox */
}

//dropdown css
.Dropdown_dropdown__ZbOaq .Dropdown_menu__ucc97 ul {
  list-style: none;
  padding-left: 0;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.cust-container2 {
  max-width: 1827px !important;
  margin: 0 15px;
}
.full-filter-box {
  background-color: $app-black2;
  padding: 26px 40px;
  margin-bottom: 35px;
  @media all and (max-width: 1359px) {
    padding: 26px 30px;
  }
  @media all and (max-width: 991px) {
    padding: 20px 20px;
  }
  form {
    display: flex;
    align-items: center;
    gap: 20px;
    @media all and (max-width: 991px) {
      flex-wrap: wrap;
    }

    .input-icon {
      margin-bottom: 0;
      width: 100%;
      max-width: 406px;
      position: relative;
      .select__control {
        border-radius: 4px !important;
      }
      @media all and (max-width: 991px) {
        max-width: 100%;
      }
      &.btn-box {
        background-color: transparent;
      }
      &.without {
        img {
          display: none;
          @media all and (max-width: 767px) {
            display: block;
          }
        }
      }
      img {
        position: absolute;
        left: 20px;
        top: 0;
        bottom: 0;
        margin: auto;
        z-index: 9;
        @media all and (max-width: 1359px) {
          left: 10px;
          width: 14px !important;
        }
      }
    }
    .arrival-box {
      max-width: 543px;
      width: 100%;
      margin-top: 0;
      display: flex;
      align-items: center;
      &.m406 {
        max-width: 406px;
        @media all and (max-width: 991px) {
          max-width: 100%;
        }
        .sleep-text {
          position: absolute;
          left: 70px;
          top: 0;
          bottom: 0;
          margin: auto 0;
          z-index: 9;
          height: fit-content;
          color: $app-black2;
          font-size: 18px;
          font-weight: 400;
          @media all and (max-width: 1359px) {
            font-size: 16px;
          }
          @media all and (max-width: 991px) {
            font-size: 14px;
          }
        }
        .select__value-container {
          padding: 14px 12px 14px 40%;
          @media all and (max-width: 1359px) {
            padding: 2px 12px 2px 40%;
          }
        }
      }
      @media all and (max-width: 991px) {
        max-width: 100%;
      }
      @media all and (max-width: 767px) {
        flex-wrap: wrap;
        gap: 20px;
      }
      .select-form-select {
        width: 100%;
      }
      .input-icon:first-child {
        input {
          border-radius: 4px 0 0 4px !important;
          @media all and (max-width: 767px) {
            border-radius: 4px !important;
          }
        }
      }
      .input-icon:last-child {
        margin-left: 2px;
        @media all and (max-width: 767px) {
          margin-left: 0px;
        }
        input {
          border-radius: 0 4px 4px 0 !important;
          @media all and (max-width: 767px) {
            border-radius: 4px !important;
          }
        }
      }
      .form-control {
        padding: 16px 12px 16px 48px;
        color: $app-black2;
        font-size: 18px;
        font-weight: 400;
        @media all and (max-width: 1359px) {
          padding: 2px 12px 2px 30px;
          font-size: 16px;
        }
        @media all and (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
    .select__value-container {
      padding: 14px 12px 14px 48px;
      @media all and (max-width: 1359px) {
        padding: 2px 12px 2px 30px;
      }
      .select__single-value,
      .select__placeholder {
        color: $app-black2;
        font-size: 18px;
        font-weight: 400;
        @media all and (max-width: 1359px) {
          font-size: 16px;
        }
        @media all and (max-width: 991px) {
          font-size: 14px;
        }
      }
    }
    .sleep-box {
      max-width: 271px;
      width: 100%;
      margin-top: 0;
      @media all and (max-width: 991px) {
        max-width: 100%;
      }
      .select-form-select {
        width: 100%;
      }
    }
    .input {
      height: 60px;
      outline: none !important;
      box-shadow: none !important;
      &::-webkit-input-placeholder {
        color: $app-black2;
      }

      &::-moz-placeholder {
        color: $app-black2;
      }

      &:-ms-input-placeholder {
        color: $app-black2;
      }

      &:-moz-placeholder {
        color: $app-black2;
      }
      &.active {
        font-size: 18px !important;
        @media all and (max-width: 1359px) {
          font-size: 16px;
        }
      }
      @media all and (max-width: 1359px) {
        height: 42px;
      }
    }
    .select-form-select .select__control {
      height: 60px;
      border-color: transparent !important;
      box-shadow: none !important;
      @media all and (max-width: 1359px) {
        height: 42px;
      }
      .select__indicator-separator {
        display: none !important;
      }
    }
    .full-btn {
      height: 60px;
      @media all and (max-width: 1359px) {
        height: 42px;
      }
    }
  }
  .full-btn {
    font-size: 22px;
    font-weight: 500;
    color: $app-black2;
    min-width: 256px;
    @media all and (max-width: 1359px) {
      min-width: 180px;
      font-size: 18px;
    }
    @media all and (max-width: 991px) {
      min-width: 150px;
      font-size: 16px;
    }
  }
}
.diff-table {
  background-color: $app-black2;
  margin-bottom: 0;
  tbody td {
    vertical-align: middle;
    border-bottom: 2px solid #fff;
    padding: 18px 13px;
    &:first-child {
      width: 389px;
      min-width: 300px;
    }
    p {
      &.common {
        font-size: 16px;
        line-height: 20px;
        font-weight: 400;
        color: #fff;
        max-width: 389px;
      }
    }
    .full-day {
      display: flex;
      gap: 22px;
      align-items: center;
      justify-content: center;
      text-align: center;
    }
    .days-div {
      span {
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
        color: #fffcc5;
      }
      h5 {
        font-size: 24px;
        font-weight: 700;
        line-height: 20px;
        color: #eeab47;
      }
    }
    .select-data-div {
      position: relative;
      .select-data-text {
        position: absolute;
        left: 17px;
        top: 0;
        bottom: 0;
        margin: auto 0;
        z-index: 1;
        height: fit-content;
        color: $app-black2;
        font-size: 12px;
        font-weight: 400;
      }
    }
    .select-form-select {
      min-width: 200px;
    }
    .select-form-select .select__control {
      box-shadow: none !important;
      border: 1px solid #1f3a46 !important;
      border-radius: 4px !important;
      .select__indicator-separator {
        display: none !important;
      }
    }
    .select__value-container {
      padding: 5px 12px 5px 60%;
      @media all and (max-width: 1359px) {
        padding: 2px 12px 2px 60%;
      }
      .select__single-value,
      .select__placeholder {
        color: $app-black2;
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  .action-btn {
    display: inline-block;
    border: none;
    outline: none;
    box-shadow: none;
    height: 30px;
    width: 30px;
    background: $app-secondary-color;
    color: #fff;
    font-size: 16px;
    line-height: 30px;
    border-radius: 3px;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease all;

    svg {
      margin-top: -7px;
    }

    &:hover {
      background-color: #000;
      color: #fff;
    }
  }
}
.full-btn {
  display: inline-block;
  border: none;
  outline: none;
  box-shadow: none;
  height: 40px;
  background: $app-secondary-color;
  color: #fff;
  font-size: 16px;
  line-height: 40px;
  border-radius: 3px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 204px;
  max-width: 204px;
  transition: 0.3s ease all;
  &:hover {
    background-color: #000;
    color: #fff;
  }
}

.select__menu-portal {
  z-index: 999999 !important;
}
.select__menu {
  border-radius: 10px !important;
  padding: 5px 0;
  z-index: 99 !important;
  .select__menu-list {
    max-height: 150px;
    .select__option {
      word-break: break-all;
      color: $black;
      font-weight: 500;
      &.select__option--is-focused {
        background: rgba(#eb8b3d, 0.2);
      }
      &.select__option--is-selected {
        background: #eb8b3d;
        color: $app-primary-color;
      }
    }
  }
}

//calendar
.flatpickr-day.selected,
.flatpickr-day.startRange,
.flatpickr-day.endRange,
.flatpickr-day.selected.inRange,
.flatpickr-day.startRange.inRange,
.flatpickr-day.endRange.inRange,
.flatpickr-day.selected:focus,
.flatpickr-day.startRange:focus,
.flatpickr-day.endRange:focus,
.flatpickr-day.selected:hover,
.flatpickr-day.startRange:hover,
.flatpickr-day.endRange:hover,
.flatpickr-day.selected.prevMonthDay,
.flatpickr-day.startRange.prevMonthDay,
.flatpickr-day.endRange.prevMonthDay,
.flatpickr-day.selected.nextMonthDay,
.flatpickr-day.startRange.nextMonthDay,
.flatpickr-day.endRange.nextMonthDay {
  background: $app-secondary-color !important;
  border-color: $app-secondary-color !important;
}

.flatpickr-current-month .flatpickr-monthDropdown-months,
.flatpickr-current-month
  .flatpickr-monthDropdown-months
  .flatpickr-monthDropdown-month {
  background: $app-secondary-color !important;
}

.flatpickr-calendar {
  .flatpickr-month,
  .flatpickr-weekdays,
  span.flatpickr-weekday,
  .flatpickr-monthDropdown-months {
    background-color: $app-secondary-color !important;

    &:hover {
      background-color: $app-secondary-color !important;
    }
  }

  &.arrowTop:after {
    border-bottom-color: $app-secondary-color !important;
  }

  .flatpickr-months .flatpickr-prev-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-next-month,
  .flatpickr-months .flatpickr-prev-month.flatpickr-prev-month,
  .flatpickr-months .flatpickr-next-month.flatpickr-prev-month {
    &:hover {
      svg {
        fill: $app-secondary-color !important;
      }
    }
  }
  .flatpickr-day.flatpickr-disabled,
  .flatpickr-day.flatpickr-disabled:hover {
    color: rgba($black, 0.5);
  }
}

.demo-app {
  font-family: Arial, sans-serif;
  font-size: 26px;
}

.demo-app-top {
  margin: 0 0 3em;
}

.demo-app-calendar {
  margin: 0 auto;
  max-width: 500px;
}

.w-100 {
  width: 100%;
}

.search-fields{
  .col-sm-3{
    padding: 0;
  }
  .input-icon{
    position: relative;

    .react-datepicker-wrapper{
      .react-datepicker__input-container{
       > input{
        height: 44px;
        width: 200px;
        border-radius: 4px;
        outline: none;
        border: none;
        padding: 5px 10px 5px 37px;
       }
      
      }
    }
    .calendar-icon{
      position: absolute;
      left: 10px;
      top: 13px;
    }
  }
}

.modal-search-field{
  .row{
    width: 100%;
    gap: 20px;
  }
 .input-icon{
  .react-datepicker-wrapper{
    .react-datepicker__input-container{
      input{
        border: 1px solid #172840;
      }
    }
  }
 }
}
