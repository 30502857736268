@font-face {
  font-family: "lasher-icons";
  src: url("../fonts/lasher-icons.eot?9q8qnw");
  src: url("../fonts/lasher-icons.eot?9q8qnw#iefix") format("embedded-opentype"),
    url("../fonts/lasher-icons.ttf?9q8qnw") format("truetype"),
    url("../fonts/lasher-icons.woff?9q8qnw") format("woff"),
    url("../fonts/lasher-icons.svg?9q8qnw#lasher-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "lasher-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-remove:before {
  content: "\e917";
}

.icon-down-arrow:before {
  content: "\003E";
  transform: rotate(90deg);
}

.icon-download:before {
  content: "\e901";
}

.icon-edit:before {
  content: "\e902";
}

.icon-file:before {
  content: "\e903";
}

.icon-headphones:before {
  content: "\e904";
}

.icon-info:before {
  content: "\e905";
}

.icon-lock:before {
  content: "\e906";
}

.icon-logo-icon:before {
  content: "\e907";
}

.icon-mail:before {
  content: "\e908";
}

.icon-map-marker:before {
  content: "\e909";
}

.icon-printer:before {
  content: "\e90a";
}

.icon-repeat:before {
  content: "\e90b";
}

.icon-search:before {
  content: "\e90c";
}

.icon-up-arrow:before {
  content: "\e90d";
}

.icon-upload:before {
  content: "\e90e";
}

.icon-user:before {
  content: "\e90f";
}

.icon-user-small:before {
  content: "\e910";
}

.icon-warning:before {
  content: "\e911";
}

.icon-add:before {
  content: "\e912";
}

.icon-camera:before {
  content: "\e913";
}

.icon-cart:before {
  content: "\e914";
}

.icon-close:before {
  content: "\e915";
}

.icon-credit-card:before {
  content: "\e916";
}

.icon-right-arrow:before {
  content: "\003E";
}
.icon-left-arrow:before {
  content: "\003C";
}
.icon-plus:before{
  content:"\002B"
}
.icon-vertical-line:before{
  content:"\007C"
}
.icon-at:before{
  content:"\0040";
  font-weight: 900;
}