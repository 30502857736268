//
$color_f2: #f2f2f2;
$darkgray: #646464;
$color_70: #707070;
$color_3f: #3f3f3f;

@mixin transition5s {
  transition: 0.5s ease-in-out !important;
}
@mixin scroll_horizontal {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $white;
  }
  &::-webkit-scrollbar {
    height: 0.375rem;
    background-color: $white;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($app-secondary-color, 1);
  }
}
@mixin scroll_vertical {
  &::-webkit-scrollbar-track {
    border-radius: 0.125rem;
    background-color: $white;
  }

  &::-webkit-scrollbar {
    width: 0.375rem;
    background-color: $white;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 0.125rem;
    background-color: rgba($app-secondary-color, 1);
  }
}

@mixin all_center {
  display: flex;
  align-items: center;
  justify-content: center;
}
@mixin common_13 {
  font-size: 0.813rem;
  line-height: 1rem;
  color: $darkgray;
  font-weight: 400;
}
.common-13 {
  @include common_13();
}
@mixin common_14 {
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: $darkgray;
  font-weight: 400;
}
.common-14 {
  @include common_14();
}
@mixin common_16 {
  font-size: 1rem;
  line-height: 1.375rem;
  color: $darkgray;
  font-weight: 400;
}
.common-16 {
  @include common_16();
}
.side-menu {
  width: 420px;
  position: fixed;
  height: 100%;
  top: 0;
  left: -110%;
  z-index: 9999;
  box-shadow: 5px 0px 10px 0px rgba($black, 0.29);
  background-color: $white;
  max-width: calc(100% - 15px);
  @include transition5s();
  &.active {
    left: 0;
  }
  .page-sub-title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0;
  }
  .grid-searchbar {
    margin-top: 15px;
    margin-bottom: 0 !important;
  }
  // form-group

  .form-group {
    margin-bottom: 0.938rem !important;
    &.form-check {
      padding-left: 0;
    }
    label {
      position: relative;
      cursor: pointer;
      @include common_14();
      color: $black !important;
      padding-left: 2.5rem;
      margin-bottom: 0;
      cursor: pointer;
      span {
        margin-left: 0 !important;
        a {
          text-decoration: underline !important;
          color: $black !important;
        }
      }
    }

    input[type="checkbox"] {
      display: none;
    }

    input[type="checkbox"] + label:last-child {
      margin-bottom: 0;
    }

    input[type="checkbox"] + label:before {
      content: "";
      position: absolute;
      display: block;
      width: 1.25rem;
      height: 1.25rem;
      border: 0.063rem solid $black;
      left: 0;
      top: 0;
      opacity: 1;
      -webkit-transition: all 0.12s, border-color 0.08s;
      transition: all 0.12s, border-color 0.08s;
      border-radius: 0.375rem;
    }
    input[type="checkbox"]:checked + label:before {
      border: 0.063rem solid $app-secondary-color;
    }
    input[type="checkbox"]:checked + label:after {
      content: "";
      position: absolute;
      width: 0.375rem;
      height: 0.75rem;
      top: 0.125rem;
      left: 0.438rem;
      border-radius: 0;
      opacity: 1;
      border: 0.125rem solid $black;
      border-top-color: transparent;
      border-left-color: transparent;
      -webkit-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
  .card {
    &.main-by-card {
      border: 0;
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 1.25rem;
      display: flex !important;
      flex-direction: column;
      height: 100vh;
      &:last-child {
        margin-bottom: 0;
        .card-body.main-by-card-body {
          padding-bottom: 0;
        }
      }
      @media (max-width: map-get($mediaSizes, "lg")) {
        margin-bottom: 1rem;
      }
      .scroll-data {
        max-height: unset !important;
        max-width: 100%;
        padding-right: 0 !important;
        scrollbar-gutter: stable;
      }
      .card-header.main-by-card-header {
        background-color: $color_f2;
        padding: 1.25rem 0.625rem 1.188rem;
        display: block !important;
        align-items: center;
        justify-content: space-between;
        border: 0.063rem solid $secondary;
        border-left: 0;
        border-right: 0;
        flex: initial;
        position: relative;
        .close-btn {
          top: 10px;
          right: 10px;
          position: absolute;
          width: 25px;
          height: 25px;
          padding: 0;
          @include all_center();
          background-color: $app-secondary-color;
          color: $white;
          border-radius: 5px;
        }
        div.flexis {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
        @media (max-width: map-get($mediaSizes, "lg")) {
          padding: 1rem 0.625rem 1rem;
          h3 {
            @include common_14();
            font-weight: 700;
            color: $app-secondary-color;
          }
          p {
            @include common_13();
          }
        }
        p {
          line-height: 1.125rem !important;
          margin-bottom: 0;
          cursor: pointer;
        }
      }
      .card-body.main-by-card-body {
        padding: 0rem 0.625rem;
        background-color: $white;
        @media (max-width: map-get($mediaSizes, "lg")) {
          padding: 0rem 0.625rem 1rem;
        }
        .search-box {
          input.form-control {
            border-left: 0;
            border-right: 0;
            border-top: 0;
            border-bottom: 0.063rem solid $color_70;
            border-radius: 0;
            font-size: 0.875rem;
            line-height: 1.125rem;
            font-weight: 400;
            text-transform: uppercase;
            margin-right: 0.813rem;
            padding: 0.125rem 0;
            height: 1.5rem;
            color: $darkgray;
            @media (max-width: map-get($mediaSizes, "lg")) {
              @include common_13();
            }
            &:focus {
              box-shadow: none;
              border-bottom: 0.063rem solid $app-secondary-color;
            }

            &::placeholder {
              color: $darkgray;
              font-weight: 400;
            }
            &::-webkit-input-placeholder {
              color: $darkgray;
              font-weight: 400;
            }
            &::-moz-placeholder {
              color: $darkgray;
              font-weight: 400;
            }
            &:-ms-input-placeholder {
              color: $darkgray;
              font-weight: 400;
            }
            &:-moz-placeholder {
              color: $darkgray;
              font-weight: 400;
            }
          }
          .input-group {
            align-items: center;
            .input-group-text {
              border: 0;
              background-color: transparent;
              border-radius: 0;
              .btn.btn-secondary {
                width: 2.875rem;
                height: 2.875rem;
                border-radius: 0.375rem;
                padding: 0;
                @include all_center();
                @media (max-width: map-get($mediaSizes, "xl")) {
                  width: 2.5rem;
                  height: 2.5rem;
                }
                @media (max-width: map-get($mediaSizes, "lg")) {
                  width: 2.125rem;
                  height: 2.125rem;
                }
              }
            }
          }
        }
        .accordian-font {
          background-color: $white;
          padding: 15px 0;
          position: relative;
          @include common_16();
          font-weight: 700;
          color: $app-secondary-color;
          @media (max-width: map-get($mediaSizes, "lg")) {
            @include common_14();
            font-weight: 700;
            color: $app-secondary-color;
          }
          &::before {
            right: 0.75rem;
            top: 0;
            bottom: 0;
            margin: auto 0;
            left: unset;
            position: absolute;
            @media (max-width: map-get($mediaSizes, "lg")) {
              right: 0.25rem;
            }
          }
        }
        .accordion {
          border: 0;
          border-radius: 0;
          .accordion__item + .accordion__item {
            border-bottom: 0.063rem solid $black;
            border-top: 0 !important;
          }
          .accordion__item {
            margin-bottom: 0rem;
            border-bottom: 0.063rem solid $black;
            &:last-child {
              border-bottom: 0 !important;
            }
            .accordion__heading {
              padding: 0 0 1rem 0;
            }
            .accordion__panel {
              padding: 1rem 0 0 0;
              @media (max-width: map-get($mediaSizes, "lg")) {
                padding: 0.5rem 0 0 0;
              }
              .scroll-data {
                margin-bottom: 1.5rem;
                flex: 1%;
                z-index: 999;
                @include transition5s();
                overflow-x: hidden;
                overflow-y: auto;
                max-width: calc(100% - 12px);
                @media (max-width: map-get($mediaSizes, "lg")) {
                  max-width: 100%;
                }
                padding-right: 10px;
                &::-webkit-scrollbar-track {
                  border-radius: 0;
                  background-color: $white;
                  border: 1px solid $color_70;
                }

                &::-webkit-scrollbar {
                  width: 0.5rem;
                  background-color: $white;
                }

                &::-webkit-scrollbar-thumb {
                  border-radius: 0;
                  background-color: rgba($color_3f, 1);
                }
                .form-group {
                  &.mb-0 {
                    margin-bottom: 0 !important;
                  }
                }
                .radio-data {
                  &.with-inline {
                    ul {
                      list-style: none;
                      padding-left: 0;
                      margin-bottom: 0;
                      li {
                        display: inline-block;
                        margin-bottom: 0.5rem;
                      }
                    }
                  }
                }
                .checkbox-data {
                  .form-group {
                    margin-bottom: 0 !important;
                    line-height: 1.25rem;
                  }
                  ul {
                    list-style: none;
                    padding-left: 0;
                    margin-bottom: 0;
                    li {
                      display: block;
                      margin-bottom: 1.125rem;
                      &:last-child {
                        margin-bottom: 0;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
