@media (max-width: 1930px) {
  .main-header {
    .top-nav {
      height: 150px;
    }
  }

  .content-page.catalogue-section {
    padding-top: 0px !important;
  }

  .invoice-filter {
    div {
      margin-right: 10px;
    }

    input {
      border: 1px solid #000000;
      height: 40px;
      width: auto !important;
    }
  }

  .invoice-search {
    input {
      width: 350px !important;
    }
  }

  .header-search-box input {
    min-width: 667px !important;
  }

  .user-dropdown {
    transform: translate3d(199px, 74px, 0px) !important;
  }

  .hRnZkV {
    grid-gap: 45px !important;
  }

  .carousel img {
    width: 290px;
    height: 290px;
  }

  // .carousel img {
  //   width: 290px;
  //   height: 290px;
  // }

  .kVtMtA,
  .bhRnqO {
    height: 100px !important;
    width: 24px !important;
  }

  .promotion-grid .promotion-img {
    height: 150px;
    width: 150px;
  }

  .product-details-view {
    .product-details {
      margin-bottom: 90px;
    }
  }

  .list-details {
    margin-top: 25px;

    .row {
      div.list-price {
        .col-1 {
          font-size: 22px;
          font-weight: 700;
          color: #b2b2b2;
        }

        .col-2 {
          font-size: 22px;
          font-weight: 700;
          color: $app-secondary-color;
        }
      }

      div.net-price {
        .col-1 {
          font-size: 27px;
          font-weight: 700;
          color: #b2b2b2;
        }

        .col-2 {
          font-size: 27px;
          font-weight: 700;
          color: $app-secondary-color;
        }

        .net {
          color: #000000 !important;
        }
      }
    }
  }

  .prducts-btns {
    .btn {
      width: 250px;
    }
  }

  .popover {
    left: 5% !important;
  }

  .labels {
    font-size: 27px !important;
  }

  .product-details-view .product-details .detail-wrapper.code {
    font-size: 27px;
  }

  .website-btn {
    width: 100% !important;

    div {
      justify-content: flex-end;
    }
  }
}

@media (max-width: 1850px) {
  .popover {
    left: 3% !important;
  }
}

@media (max-width: 1787px) {
  .main-header .user-login {
    right: 45px;
    // margin-top: -2px;
    z-index: 2;
  }

  .popover {
    left: 2% !important;
  }
}

@media (max-width: 1730px) {
  .popover {
    left: 0% !important;
  }
}

@media (max-width: 1650px) {
  .prducts-btns {
    .btn {
      width: 200px;
    }
  }
  .invoice-filter {
    div {
      margin-right: 10px;
    }

    input {
      border: 1px solid #000000;
      height: 40px;
      width: 146px;
    }
  }

  .invoice-search {
    input {
      width: 225px !important;
    }
  }

  .user-dropdown {
    transform: translate3d(199px, 68px, 0px) !important;
  }

  /*-------common------*/
  // .cust-container {
  //   padding: 0 15px;

  // }

  .main-header .user-login {
    right: 45px;
    margin-top: 6px;
    z-index: 2;
  }

  /*-------main header----------*/
  .main-header {
    // .right-box{
    //   bottom: 4px;

    .user-login {
      .nav-link {
        // align-items: flex-start;
        span.user-name {
          max-width: 300px;
          font-size: 12px;
          line-height: normal;
          padding-right: 6px;
        }
      }
    }

    .app-links-nav {
      .header-search-box {
        input {
          min-width: auto;
          width: 186px;
        }
      }
    }
  }

  /*-------admin-dashboard---------*/
  .section-header {
    .input-box {
      margin-top: -8px;
    }
  }

  /*-------home-section---------*/
  .home-section {
    .material-section {
      .material-links-row {
        .material-box {
          height: 250px;
        }
      }

      .subscribe-box {
        padding: 0 202px 0 66px;
      }
    }
  }

  /*------------newsCustomer-section----------*/
  .newsCustomer-section {
    .news-row {
      margin-right: -56.25px;
      margin-left: -56.25px;

      .news-col {
        padding: 0 56.25px;
      }
    }

    .news-card {
      margin-bottom: 40px;
    }
  }

  /*------------catalogue-section -------------*/
  .catalogue-section {
    .catalogue-card {
      .product-image {
        height: 283px;
      }
    }
  }

  /*----------------product-details-view----------------*/
  .product-details-view {
    .product-image-wrapper {
      .product-image {
        height: 510px;
      }
    }

    .product-details {
      h3.productname {
        margin-bottom: 26px;
        font-size: 30px;
      }

      .product-summary-details {
        .detail-wrapper {
          margin-bottom: 30px;
        }
      }

      .product-quantity {
        margin-bottom: 40px;
      }
    }

    .product-description {
      margin-bottom: 25px;
    }

    .product-more-detail {
      padding-top: 45px;
    }
  }

  // .traning-client-section {
  //   .select-training-box {
  //     margin-bottom: 10px;
  //   }
  // }
  .cust-container {
    padding: 0px 80px;
  }

  .hRnZkV {
    padding: 0px 60px;
  }

  .popover {
    left: 5% !important;
  }
}

@media (max-width: 1590px) {
  .invoice-filter {
    div {
      margin-right: 10px;
    }

    input {
      border: 1px solid #000000;
      height: 40px;
      width: 146px;
    }
  }

  .invoice-search {
    input {
      width: 166px !important;
    }
  }
}

@media (max-width: 1460px) {
  .content-page.catalogue-section {
    padding-top: 25px !important;
  }
  .kVtMtA,
  .bhRnqO {
    height: 50px !important;
    width: 16px !important;
  }

  .header-search-box input {
    min-width: 430px !important;
  }

  .prducts-btns {
    .btn {
      width: auto;
    }
  }

  .product-details-view .product-details .detail-wrapper.code {
    font-size: 24px;
  }

  /*-----------stocksheetform-------------*/
  .stocksheets-create-placeholder {
    .align-save-placeholder {
      .save {
        top: 235px;
        color: white;
        left: 1675px;
        width: 90px;
        height: 40px;
        background: #1d5532 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        display: inline;
        float: inline-end;
      }
    }
  }

  .labels {
    font-size: 23px !important;
  }
}

@media (max-width: 1450px) {
  .hRnZkV {
    grid-gap: 10px !important;
  }

  .carousel img {
    object-fit: contain;
    width: 217px;
    height: 217px;
  }

  /*-------main header----------*/
  .main-header {
    .user-login {
      // right: 70px;
      // bottom: 55px!important;
      // margin-top: 12px;
    }

    .top-nav {
      height: 150px;

      .navbar-brand {
        // max-width: 88px;
        margin-left: 0px;
      }

      // .right-box {
      //   padding: 20px 15px 20px 0;
      // }

      .user-login .nav-link,
      .add-cart {
        padding: 0 12px;
        margin-top: 5px;
      }
    }
    .custom-page {
      padding-top: 95px;
    }
    .app-links-nav {
      .nav-link {
        padding: 0 8px;
        font-size: 12px;
      }
    }

    &.login-header {
      .top-nav {
        // height: 65px;
        .navbar-brand {
          max-width: 78px;
        }

        .app-title {
          padding: 28px 0;
          font-size: 10px;
        }
      }
    }
  }

  .marketing-client-section {
    .right-box {
      display: flex;

      .select-out-two {
        margin-top: 0px;
      }
    }
  }

  /*----------main container --------------*/
  main {
    padding-top: 125px;
    padding-bottom: 102px;
  }

  main.login-main-page {
    padding-top: 65px;
  }

  /*------------main footer --------*/
  .main-footer {
    height: auto;

    .cust-container {
      flex-direction: column;
      align-items: center;
    }

    .center-box {
      position: static;
    }

    .footer-link {
      line-height: 30px;
    }

    nav {
      li {
        a {
          line-height: 15px;
        }
      }
    }
  }

  /*-----------login-------------*/
  .login-section {
    min-height: calc(100vh - 65px - 50px);
  }

  /*-----------stocksheetform-------------*/
  .stocksheets-create-placeholder {
    .align-save-placeholder {
      .save {
        top: 235px;
        color: white;
        left: 1675px;
        width: 70px;
        height: 30px;
        background: #1d5532 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        display: inline;
        float: inline-end;
      }
    }
  }

  .contactus {
    .contact-header {
      text-align: center;
      margin-bottom: 40px;

      h2 {
        font-size: 35px !important;
        color: #b2b2b2;
      }

      label {
        font-size: 22px;
      }
    }

    .contact-body {
      h3 {
        font-size: 25px;
        margin-bottom: 20px;
      }
    }
  }
}

@media (max-width: 1366px) {
  .list-details {
    margin-top: 25px;

    .row {
      div.list-price {
        .col-1 {
          font-size: 18px;
          font-weight: 700;
          color: #b2b2b2;
          margin-bottom: 10px;
        }

        .col-2 {
          font-size: 18px;
          font-weight: 700;
          color: $app-secondary-color;
          margin-bottom: 10px;
        }
      }

      div.net-price {
        .col-1 {
          font-size: 20px;
          font-weight: 700;
          color: #b2b2b2;
          margin-bottom: 10px;
        }

        .col-2 {
          font-size: 18px;
          font-weight: 700;
          color: $app-secondary-color;
          margin-bottom: 10px;
        }

        .net {
          color: #000000 !important;
        }
      }
    }
  }

  .list-details {
    margin-top: 25px;

    .row {
      div.list-price {
        .col-1 {
          font-size: 18px;
          font-weight: 700;
          color: #b2b2b2;
        }

        .col-2 {
          font-size: 18px;
          font-weight: 700;
          color: $app-secondary-color;
        }
      }

      div.net-price {
        .col-1 {
          font-size: 22px;
          font-weight: 700;
          color: #b2b2b2;
        }

        .col-2 {
          font-size: 18px;
          font-weight: 700;
          color: $app-secondary-color;
        }

        .net {
          color: #000000 !important;
        }
      }
    }
  }

  .product-details-view .product-details .detail-wrapper.code {
    font-size: 22px;
  }

  .main-header {
    .app-links-nav {
      font-size: 10px;
    }
  }

  /*--------common---------------*/
  .section-header {
    height: 33px;
    // padding: 8px 28px 0 28px;
    background-size: 80px;

    span {
      line-height: 33px;
      padding-right: 0px;
      border-bottom: 3px solid #3f3f3f;
    }
  }

  /*--------------Switch-Account-section---------------------*/
  .switch-account-header {
    margin: 0;

    h2.with-abs-button {
      position: relative;

      button {
        position: absolute;
        right: 50px;
        top: -3px;
      }
    }
  }

  /*-------login section--------------*/
  .login-section {
    .login-box {
      height: auto;
      padding-bottom: 20px;
      width: 570px;
      background-size: 40px;

      .section-header {
        height: 60px;
        padding: 16px 0px;
        background-size: 80px;
        background: $app-secondary-color;

        span {
          line-height: 33px;
          padding-right: 0px;
          color: #fff;
        }
      }

      .forget-link {
        position: static;
      }
    }
  }

  /*-------home-section---------*/
  .home-section {
    .material-section {
      h2 {
        font-size: 25px;
        line-height: 30px;
      }

      .material-links-row {
        .material-box {
          height: 250px;

          // .text-wrapper {
          //   padding: 0 50px;
          //   padding-left: 27px;
          // }
        }
      }

      .subscribe-box {
        padding: 0 202px 0 66px;
      }
    }

    .with-abs-button {
      position: relative;

      button {
        position: absolute;
        right: 3px;
        top: -3px;
      }

      .view {
        color: #000 !important;
      }
    }
  }

  /*------------newsCustomer-section----------*/
  .newsCustomer-section {
    .news-row {
      margin-right: -15px;
      margin-left: -15px;

      .news-col {
        padding: 0 15px;
      }
    }

    .news-card {
      margin-bottom: 40px;
    }
  }
}

@media (max-width: 1340px) {
  .main-header {
    .app-links-nav {
      .user-login {
        // .nav-link {
        //   margin: 0px 80px;
        // }
      }

      .nav-link {
        padding: 0 8px;
        font-size: 13px;
      }
    }
  }

  .popover {
    left: 2% !important;
  }
}

@media (max-width: 1350px) {
  .content-page.catalogue-section {
    padding-top: 25px !important;
  }

  .with-sidebar {
    .sidebar-wrapper {
      min-width: 312px;
      width: 312px;
      max-width: 312px;
    }

    .sidebar-content {
      max-width: calc(100% - 312px - 22px);
    }
  }

  /*--------------Switch-Account-section---------------------*/
  .switch-account-header {
    margin: 0;
    // .section-header{
    // .btn{
    // //   margin-left:59%;
    // //   min-width: 100px;
    // position: relative;
    // bottom: 13px;
    // //  margin-bottom: 5px;

    // }
    // }
    h2.with-abs-button {
      position: relative;

      button {
        position: absolute;
        right: 50px;
        top: 0px;
      }
    }
  }

  /*----------------product-details-view----------------*/
  .product-details-view {
    .product-details {
      margin-bottom: 40px;

      .product-image {
        height: 440px;
      }
    }

    .product-details {
      h3.productname {
        font-size: 24px;
        line-height: 29px;
      }

      .product-quantity {
        margin-bottom: 25px;
      }
    }

    .product-more-detail {
      padding-top: 15px;
    }
  }
}

@media (max-width: 1294px) {
  .main-header {
    .app-links-nav {
      .nav-link {
        padding: 0 8px;
        font-size: 13px;
      }
    }
  }

  .popover {
    left: 2% !important;
  }
}

@media (max-width: 1270px) {
  .main-header {
    .app-links-nav {
      .nav-link {
        padding: 0 8px;
        font-size: 10px;
      }
    }
  }

  .tough-title {
    margin-left: 40px;
  }

  .made-title {
    margin-left: 40px;
  }
}

@media (max-width: 1215px) {
  .tough-title {
    margin-left: 60px;
  }

  .made-title {
    margin-left: 60px;
  }

  .header-search {
    padding: 8px 0 8px 12px !important;
    width: 350px;
  }

  .header-search-box input {
    min-width: 350px !important;
  }
}

@media (max-width: 1190px) {
  .main-header {
    .app-links-nav {
      .nav-link {
        padding: 0 6px;
        font-size: 10px;
      }
    }
  }

  .tough-title {
    margin-left: 100px;
  }

  .made-title {
    margin-left: 100px;
  }
}

@media (max-width: 1150px) {
  /*-------main header----------*/
  .main-header {
    .app-links-nav {
      .nav-link {
        padding: 0 5px;
        font-size: 10px;
      }
    }
  }

  /*------common-------------*/
  .newslist-wrapper {
    padding-left: 0px;

    .news-item {
      margin-bottom: 20px;
    }
  }

  /*------------home-section---------------------*/
  .home-section {
    .no-promotion {
      margin-top: 0px;
    }

    .section-row {
      flex-wrap: wrap;
      flex-direction: column;
      margin: 0px;

      .section-col {
        margin-bottom: 20px;
        width: 100%;

        &.left-col {
          max-width: none;
        }
      }
    }

    /*-----------stocksheetform-------------*/
    .stocksheets-create-placeholder {
      .align-save-placeholder {
        .save {
          top: 235px;
          color: white;
          left: 1675px;
          width: 90px;
          height: 40px;
          background: #1d5532 0% 0% no-repeat padding-box;
          border-radius: 6px;
          opacity: 1;
          display: inline;
          float: inline-end;
        }
      }
    }
  }

  .tough-title {
    margin-left: 120px;
  }

  .made-title {
    margin-left: 120px;
  }
}

@media (max-width: 1135px) {
  /*-----------stocksheetform-------------*/
  .stocksheets-create-placeholder {
    .align-save-placeholder {
      .save {
        top: 235px;
        color: white;
        left: 1675px;
        width: 50px;
        height: 30px;
        background: #1d5532 0% 0% no-repeat padding-box;
        border-radius: 6px;
        opacity: 1;
        display: inline;
        float: inline-end;
      }
    }
  }

  .tough-title {
    margin-left: 140px;
  }

  .made-title {
    margin-left: 140px;
  }
}

@media (max-width: 1110px) {
  .main-header {
    .app-links-nav {
      .nav-link {
        // padding: 0 8px;
        font-size: 9px;
      }
    }
  }

  .tough-title {
    margin-left: 160px;
  }

  .made-title {
    margin-left: 160px;
  }

  .header-search-box input {
    min-width: 310px !important;
  }

  .search-toggle {
    width: 100px !important;
  }

  .user-name p {
    width: 225px;
  }
}

@media (max-width: 1080px) {
  .main-header {
    .app-links-nav {
      .nav-link {
        padding: 0 6px;
        font-size: 8px;
      }
    }
  }

  .tough-title {
    margin-left: 200px;
  }

  .made-title {
    margin-left: 200px;
  }
}

@media (max-width: 1080px) {
  .tough-title {
    margin-left: 240px;
  }

  .made-title {
    margin-left: 240px;
  }
}

@media (max-width: 1035px) {
  .main-header {
    .app-links-nav {
      .nav-link {
        padding: 0 4px;
        font-size: 9px;
      }
    }
  }

  .with-sidebar {
    flex-wrap: wrap;

    .sidebar-wrapper {
      min-width: 100%;
      width: 100%;
      max-width: 100%;
      margin-bottom: 30px;
      padding-top: 0px;
    }

    .sidebar-content {
      max-width: 100%;
      width: 100%;
      min-width: 100px;
      padding-left: 0px;
    }
  }

  .account-store {
    margin-bottom: 30px;
  }

  /*---------stocksheets--------------*/
  .stock-header {
    .section-header {
      width: max-content;
      max-width: 100%;
    }
  }

  .stocksheet-table-box {
    width: 80%;
    overflow: hidden;
  }

  .marketing-client-section {
    .right-box {
      .select-out-two {
        margin-top: 0px;
      }
    }
  }

  .tough-title {
    margin-left: 260px;
  }

  .made-title {
    margin-left: 260px;
  }
}

@media (max-width: 1020px) {
  .main-header {
    .app-links-nav {
      .nav-link {
        padding: 0 6px;
        font-size: 7px;
      }
    }
  }

  .tough-title {
    margin-left: 280px;
  }

  .made-title {
    margin-left: 280px;
  }
}

@media (max-width: 1004px) {
  .marketing-client-section {
    .right-box {
      .select-out-two {
        margin-top: 10px;
      }
    }
  }
}

@media (max-width: 991px) {
  .website-btn {
    width: auto !important;
  }

  /*-------------main header--------*/
  .cust-container,
  .hRnZkV {
    padding: 0 0 0 20px;
  }

  .header-search {
    display: none !important;
  }

  .navbar {
    justify-content: space-between !important;
  }

  .user-login {
    .nav-link {
      margin: 23px 0px 16px 0px;
      display: flex;
      flex-direction: row-reverse;
    }
  }

  .brand-img {
    left: 0 !important;
  }

  .user-login-wrapper {
    width: 100%;
    margin-top: 0px;
  }

  .main-header {
    &.login-header {
      .top-nav {
        .app-title {
          padding: 14px 0;
          font-size: 10px;
        }
      }
    }
  }

  .popover {
    top: 140px !important;
    max-width: 991px;
    position: fixed !important;
    transform: none !important;
    will-change: none !important;
    height: 100%;
  }

  .popoverbody-block {
    overflow-y: auto;
    max-height: 600px;
  }

  .bs-popover-top > .arrow::after,
  .bs-popover-auto[x-placement^="top"] > .arrow::after {
    bottom: 1px;
    border-width: 0.5rem 0.5rem 0;
    border-top-color: #fff;
    display: none !important;
  }

  .nopadding {
    padding: 0 !important;
    margin-right: 100px !important;
  }

  .main-header {
    .top-nav {
      .menu-toggle {
        display: inline-block;
        width: 44px !important;
        height: 35px !important;
        margin-top: -7px !important;
      }

      .brand-img {
        margin-right: 20px !important;
        max-width: 90px;
      }
    }

    .app-links-nav {
      position: fixed;
      top: 0;
      left: -100%;
      width: 100%;
      height: 100% !important;
      // transition: 2s;
      transition-timing-function: linear;
      z-index: 1;
      font-size: 10px;
      padding-top: 90px;
      padding-left: -20px;
      overflow: auto;

      &.show {
        left: 0px !important;
        height: 100vh !important;
        width: 100%;
      }

      .cust-container {
        padding: 0 0px !important;
      }

      .menu-close-button {
        display: block;
      }

      .nav-links {
        align-items: flex-start;
        flex-direction: column;

        a,
        form {
          display: inline-block;
          width: 100%;
        }

        .header-search {
          width: calc(100% - 10px);
          display: inline !important;

          input {
            width: 100%;
            min-width: auto !important;
          }
        }

        .nav-link {
          &.active {
            background: transparent !important;
          }
        }

        .nav-link.active:before {
          transform: rotate(-90deg);
          left: 0;
          background-size: 22px;
          width: 23px;
          height: 11px;
          top: 18px;
          background-position: center;
        }
      }
    }

    .text-nav {
      font-size: 16px !important;
      padding: 0px 15px !important;
    }

    .user-login-main {
      width: 100%;

      .user-login {
        a {
          display: flex !important;
        }
      }
    }

    .right-box {
      .add-cart {
        display: none;
      }
    }

    .right-box-navbar {
      .cart-icon-login-navbar {
        display: block;
        padding: 2px;
      }
    }

    .user-login {
      position: static;
      background-color: $app-primary-color;
      align-items: flex-start;
      margin-right: 100% !important;
      width: 100%;
      flex-wrap: wrap;

      .nav-link {
        margin: 23px 0px 16px 0px !important;
      }

      .user-name {
        color: #e87335;
        font-size: 15px !important;
      }

      .main-cart {
        display: none;
      }

      .account-div {
        margin-right: 12px;
      }

      .icon-user {
        float: left;
        margin-top: 0px;
        margin-right: 15px;
        margin-left: 15px;
        margin-bottom: 12px;
      }

      .custom-dropdown.user-dropdown {
        position: static !important;
        background-color: $app-primary-color;
        transform: none !important;
        width: 100%;
        margin-top: 0px;
        display: block;
        box-shadow: none !important;

        .dropdown-item {
          display: block;
          background-color: $app-primary-color;
          font-size: 16px;
          font-weight: bold;
          padding: 10px 20px;
        }
      }
    }

    .main-header .app-links-nav a.nav-link.active {
      width: 100% !important;
    }

    .main-header .app-links-nav .nav-links .nav-link .active:before {
      padding: 0px 15px;
    }
  }

  .main-header .app-links-nav.show {
    left: 0px !important;
    height: 100% !important;
    width: 100%;
  }

  /*----------main container --------------*/
  main {
    padding-top: 75px;
  }

  /*-------home-section---------*/
  .home-section {
    .material-section {
      padding: 34px 0;

      .cust-container {
        padding: 0 0px !important;
      }

      .material-links-row {
        flex-direction: column;
        margin-bottom: 20px;

        .material-box {
          padding: 20px 0;
          margin-bottom: 20px;

          .text-wrapper {
            text-align: center;
          }
        }
      }

      .subscribe-box {
        height: auto;
        padding: 30px 15px 30px 15px;
        background-color: #d3d3d3;

        &:before {
          display: none;
        }

        .left-box {
          padding-right: 10px;
        }
      }
    }
  }

  /*-----------login-------------*/
  .login-section {
    min-height: calc(100vh - 65px - 50px);
  }

  // .text-nav{
  //   font-size: 12px !important;
  //   }
  .header-submenu {
    .popover {
      transform: none !important;
      left: 0px !important;
      top: 134px;
    }
  }

  .popovr {
    top: 134px !important;
  }

  // .sticky-header{
  //   position: sticky;
  // }
  .vicker {
    width: 89px !important;
    margin-top: 1px;
    margin-left: -12px !important;
  }

  .okapi {
    width: 89px !important;
    margin-top: 8px !important;
    margin-right: -12px !important;
  }

  .navv {
    display: block;
    height: $links-nav-height;
    height: 55px !important;
    margin-top: -14px;
  }

  .login-logo {
    margin-top: 10px !important;
  }

  .tough-title {
    margin-left: 275px;
  }

  .made-title {
    margin-left: 275px;
  }

  .main-footer {
    .cust-container {
      padding: 0 0px !important;
    }

    .right-box {
      .list-inline {
        text-align: center;
      }
    }
  }
}

@media (max-width: 960px) {
  .tough-title {
    margin-left: 345px;
  }

  .made-title {
    margin-left: 345px;
  }
}

@media (max-width: 920px) {
  .tough-title {
    margin-left: 375px;
  }

  .made-title {
    margin-left: 375px;
  }
}

@media (max-width: 880px) {
  .tough-title {
    margin-left: 390px;
  }

  .made-title {
    margin-left: 390px;
  }
}

@media (max-width: 848px) {
  .main-header {
    .top-nav {
      height: 150px;
    }
  }
}

@media (max-width: 820px) {
  /*----------report-section------------*/

  .report-section {
    .report-header {
      .from-date-input {
        margin-left: 25%;
      }

      .to-date-input {
        margin-left: 25%;
      }
    }
  }

  .text-nav {
    font-size: 12px !important;
  }

  .bs-popover-bottom > .arrow::after,
  .bs-popover-auto[x-placement^="left"] > .arrow::after {
    border-bottom-color: #1d5532;
  }
}

@media (max-width: 768px) {
  .fullNews-page .news-container {
    padding: 0px 50px;
  }

  /* Contact us */
  .contactus {
    .contact-header {
      text-align: center;
      margin-bottom: 40px;

      h2 {
        font-size: 25px !important;
        color: #b2b2b2;
      }

      label {
        font-size: 22px;
      }
    }

    .contact-body {
      .contact-details {
        .row {
          .icon-div {
            width: 100px !important;
          }

          .detail-div {
            width: 300px !important;
          }
        }
      }

      h3 {
        font-size: 23px;
        margin-bottom: 20px;
        text-align: center;
      }

      .form {
        .contact-btn {
          text-align: center;
        }
      }
    }
  }

  /*---------main----------*/
  main {
    padding-bottom: 112px;
  }

  .login-header {
    .top-nav {
      // height: 65px;
      .navbar-brand {
        max-width: 78px;
      }

      .app-title {
        padding: 10px 0;
        font-size: 0px;
      }
    }

    .text-nav {
      font-size: 12px !important;
    }
  }

  /*----------.main-footer------------*/
  .main-footer {
    .left-box {
      text-align: center;

      .footer-link {
        display: inline-block;
      }
    }
  }

  .footer-link {
    display: inline-block;
  }

  .cust-container {
    padding: 0px 20px;
  }

  .subcategory-div {
    display: block !important;

    .sub-lists {
      padding: 10px 10px;
      font-size: 11px;
      border-left: 2px solid #b2b2b2 !important;
    }
  }

  /*-------common---------------*/
  .marketing-client-section .right-box {
    display: block;
  }

  .grid-header-actions {
    .right-box {
      .outline-button,
      .outline-select {
        margin-right: 10px;
        margin-bottom: 10px;
      }
    }
  }

  .section-header {
    display: flex;
    align-items: center;
    // padding: 8px 28px 0 28px;

    span {
      width: 100%;
      font-size: 14px;
      padding-right: 0px;
      line-height: 16px;
      padding-bottom: 3px;
    }
  }

  .login-header {
    display: block;
    align-items: center;
    padding: 0;

    span {
      width: 100%;
      font-size: 14px;
      padding-right: 0px;
      line-height: 16px;
      padding-bottom: 3px;
    }
  }

  /*-----------switch account-------------------*/
  .switch-account-table-box {
    .switch-account-table {
      thead {
        width: 50%;
      }
    }
  }

  /*--------------Switch-Account-admin-section---------------------*/
  .switch-account-header {
    margin: 0;
    // .section-header{
    // .btn{
    // //   margin-left:59%;
    // //   min-width: 100px;
    // position: relative;
    // bottom: 13px;
    // //  margin-bottom: 5px;

    // }
    // }
    h2.with-abs-button {
      position: relative;

      button {
        position: absolute;
        right: 50px;
        top: 0px;
      }
    }
  }

  /*-----------stocksheets-create section-------------------*/
  .stockcreate-table-box {
    overflow: visible;
  }

  /*-----------my account-------------------*/
  .switch-table-box {
    .switch-table {
      width: 50%;
    }
  }

  /*-----------useraccount-------------------*/
  .useraccount-form {
    .inp {
      width: 40%;
    }

    .save-send {
      margin-top: -5%;
    }
  }

  /*-------home-section---------*/
  .home-section {
    .material-section {
      .subscribe-box {
        flex-direction: column;

        .left-box {
          padding-right: 0px;
          text-align: center;
          margin-bottom: 20px;
        }
      }
    }

    /*-------print-section---------*/
    @media print {
      page[size="A4"] {
        margin: 0;
        box-shadow: 0;
        border: 4px 4px 4px 4px solid black;
      }
    }
  }

  /*-------userAccount-page---------*/
  .useraccount-section {
    .content-page {
      padding-right: 0px;
    }

    .btn-box {
      padding-top: 20px;

      button {
        margin-bottom: 10px;
      }
    }
  }

  /*----------------product-details-view----------------*/
  .product-details-view {
    .product-image-wrapper,
    .product-details {
      width: 100% !important;
    }

    .product-image-wrapper {
      .product-image {
        height: 400px;
        background-size: 55%;
        margin-bottom: 20px;
      }
    }

    .product-details {
      padding-left: 0px !important;

      h3.productname {
        font-size: 24px;
        line-height: 29px;
      }

      // .product-summary-details {
      //   .col {
      //     min-width: 100px;
      //     max-width: 100px;
      //   }
      // }

      .product-quantity {
        margin-bottom: 25px;
      }
    }

    .product-more-detail {
      padding-top: 0px;
    }
  }
}

@media (max-width: 716px) {
  /*-----------useraddaccount-------------------*/
  .subcategory-div {
    display: block !important;

    .sub-lists {
      padding: 10px 10px;
      font-size: 11px;
      border-left: 2px solid #b2b2b2 !important;
    }
  }

  .user-table-data {
    .account-action {
      .icon-edit {
        padding: 5px;
        display: inline;
      }

      .icon-close {
        padding: 5px;
        display: inline;
      }
    }
  }

  /*-----------cart-------------------*/
  .upload-profilephoto {
    .upload-button {
      margin-left: 0px;
    }
  }

  .text-nav {
    font-size: 12px !important;
  }

  .labels {
    font-size: 18px !important;
  }
}

@media (max-width: 764px) {
  // .cat-btn-three {
  //   margin-top: 10px;
  // }

  .tough-title {
    font-size: 20px;
  }
}

@media (max-width: 672px) {
  .tough-title {
    font-size: 18px !important;
  }

  .cart-form {
    text-align: -webkit-center;
    // text-align: center;

    .cart-box {
      margin-top: 20px;
      width: auto !important;
      border: 1px solid #b2b2b2;

      .payment-options {
        display: block !important;
        justify-content: center;

        .payment-chk {
          .chk-style {
            color: $app-secondary-color !important;
            min-width: 240px !important;

            img {
              height: 78px;
            }
          }
        }
      }
    }

    .select-payment-box {
      padding-bottom: 20px;
    }

    .cust-control.with-icon {
      input {
        padding-left: 0px !important;
        color: #000;
      }
    }
  }
}

@media (max-width: 660px) {
  /*-----------useraddaccount-------------------*/

  .user-table-data {
    .account-action {
      .icon-edit {
        padding: 5px;
        display: inline;
      }

      .icon-close {
        padding: 5px;
        display: inline;
      }
    }
  }

  /*-----------cart-------------------*/
  .upload-profilephoto {
    .upload-button {
      margin-left: 0px;
    }
  }

  .text-nav {
    font-size: 12px !important;
  }
}

@media (max-width: 648px) {
  .cat-btn-three {
    margin-bottom: 10px;
  }
}

@media (max-width: 640px) {
  .app-title {
    padding: 26px 0;
    // font-family: $font-boldItalic;
    // position: absolute;
    left: 50px !important;
    right: 0;
    top: 0;
    margin: auto;
    text-align: center !important;

    .navbar-brand {
      padding: 8px 0;
      position: relative;
      z-index: 1;
    }
  }
}

@media (max-width: 644px) {
  .app-title {
    padding: 26px 0;
    // font-family: $font-boldItalic;
    // position: absolute;
    left: 80px !important;
    right: 0;
    top: 0;
    margin: auto;
    font-size: 10% !important;
    text-align: center !important;

    .navbar-brand {
      padding: 8px 0;
      position: relative;
      z-index: 1;
    }
  }

  .tough-title {
    margin-left: 275px;
  }

  .made-title {
    margin-left: 275px;
  }

  .main-header .top-nav .brand-img {
    margin-right: 0px !important;
  }
}

@media (max-width: 640px) {
  .fullNews-page .news-container {
    padding: 0px 30px;
  }

  .app-title {
    padding: 26px 0;
    // font-family: $font-boldItalic;
    // position: absolute;
    left: 60px !important;
    right: 0;
    top: 0;
    margin: auto;
    text-align: center !important;

    .navbar-brand {
      padding: 8px 0;
      position: relative;
      z-index: 1;
    }
  }
}

@media (max-width: 622px) {
  .tough-title {
    margin-left: 190px;
  }

  .made-title {
    margin-left: 190px;
  }

  .d-flex .col-sm-4 {
    padding-right: 0px !important;
    padding-left: 0px !important;
  }
}

@media (max-width: 600px) {
  .main-footer {
    width: 100%;
  }

  .main-header {
    .user-login {
      margin-top: 14px;
    }
  }
}

@media (max-width: 585px) {
  .main-footer {
    .center-box {
      a.logo {
        display: inline-block;
      }
    }
  }

  .app-title {
    padding: 36px 0;
    // font-family: $font-boldItalic;
    // position: absolute;
    left: 100px !important;
    position: relative;
    font-size: 10% !important;

    .navbar-brand {
      padding: 8px 0;
      // position: relative;
    }
  }

  .tough-title {
    font-size: 7px;
  }

  .made-title {
    font-size: 5px;
  }

  .home-section {
    .material-section {
      width: 590px !important;
      padding: 34px 0;
      position: relative;

      .material-links-row {
        flex-direction: column;
        margin-bottom: 20px;

        .material-box {
          padding: 20px 0;
          margin-bottom: 20px;

          .text-wrapper {
            text-align: center;
          }
        }
      }

      .subscribe-box {
        height: auto;
        padding: 30px 15px 30px 15px;
        background-color: #d3d3d3;

        &:before {
          display: none;
        }

        .left-box {
          padding-right: 10px;
        }
      }
    }
  }

  .btn-print {
    margin-top: 10px;
  }

  .modal-dialog {
    // margin-left: -13px;
    pointer-events: none;
  }
}

@media (max-width: 575px) {
  .promotion-grid .promotion-img {
    height: 150px;
    width: 150px;
  }

  .home-section .section-row .section-col.left-col {
    min-width: 100%;
  }

  .content-page .section-row .section-col {
    padding-left: 0px;
  }

  .main-header {
    // .top-nav {
    //   height: 162px;
    // }

    .user-login {
      margin-top: 76px;
    }
  }

  .tough-title {
    margin-left: 20px;
  }

  .made-title {
    margin-left: 20px;
  }

  .content-page {
    padding-top: 50px;
  }

  .btn-link {
    top: -12px !important;
    right: 3px !important;
  }
}

@media (max-width: 560px) {
  .app-title {
    padding: 36px 0;
    // font-family: $font-boldItalic;
    // position: absolute;
    left: 100px !important;
    position: relative;

    // font-size: 1px !important;
    .navbar-brand {
      padding: 8px 0;
      // position: relative;
    }

    .tough-title {
      font-size: 12px;
    }

    .made-title {
      font-size: 15px;
    }
  }

  .text-nav {
    font-size: 10px !important;
  }

  .text-nav {
    font-size: 12px !important;
  }

  .modal-dialog {
    margin: 0rem !important;
  }
}

@media (max-width: 550px) {
  main {
    padding-bottom: 142px;
  }

  .tough-title {
    font-size: 13px !important;
  }

  .made-title {
    font-size: 12px !important;
  }

  // .nav-Image-width {
  //   width: 60%;
  // }
}

@media (max-width: 525px) {
  .filters {
    display: block !important;
  }

  .app-title {
    padding: 36px 0;
    // font-family: $font-boldItalic;
    // position: absolute;
    left: 100px !important;

    .navbar-brand {
      padding: 8px 0;
      // position: relative;
    }
  }

  .btn-link {
    font-size: 13px;
    top: -12px !important;
    right: 3px !important;
  }

  .view {
    font-size: 13px;
  }

  .home-orders {
    font-size: 13px !important;
  }

  // .content-page .section-row .section-col {
  //   padding-left: 10px!important;
  //   padding-right: 10px!important;
  // }
  // .navbar-toggler{
  //   padding-left: 10px!important;
  //   padding-right: 10px!important;
  // }
}

@media (max-width: 500px) {
  // .cart-icon-login{
  //   margin-top: -72px;
  //   position: relative;
  // }
  // .btn-toggle{
  //   margin-top: -102px;
  //   position: relative;
  // }
  /*--------------Switch-Account-section---------------------*/
  .switch-account-header {
    margin: 0;

    h2.with-abs-button {
      position: relative;

      button {
        position: absolute;
        right: 4px;
        top: 4px;
        height: 40px;
        font-size: 12px;
      }
    }
  }

  /*----------------product-details-view----------------*/
  .product-details-view {
    .product-image-wrapper {
      .product-image {
        height: 245px;
      }
    }
  }

  .text-nav {
    font-size: 12px !important;
  }

  .tough-title {
    font-size: 13px !important;
  }

  .made-title {
    font-size: 12px !important;
  }

  .cat-btn-one {
    margin-bottom: 10px;
  }

  .cat-btn-two {
    margin-bottom: 10px;
  }

  // .cat-btn-three {
  //   margin-top: 10px;
  // }
}

//     .main-footer {
//       width: 110%  !important;
//     //   .cust-container{
//     //   width: 330px !important;

//     // }

// }
@media (max-width: 480px) {
  .main-header .user-login .nav-link span.user-name {
    font-size: 16px;
  }

  .tough-title {
    font-size: 12px !important;
  }

  .modal-text {
    font-size: 12px !important;
  }
}

@media (max-width: 466px) {
  .contactus {
    .contact-header {
      h2 {
        font-size: 20px !important;
      }

      label {
        font-size: 18px;
      }
    }

    .contact-body {
      h3 {
        font-size: 18px;
        text-align: center;
      }

      .contact-details {
        .row {
          .icon-div {
            width: 50px !important;
          }

          .detail-div {
            width: 200px !important;
          }
        }

        div.hours {
          p {
            font-size: 16px;
          }
        }
      }
    }
  }

  main {
    padding-bottom: 180px !important;
  }

  .tough-title {
    font-size: 12px !important;
  }

  .made-title {
    font-size: 10px !important;
  }

  .btn-print {
    margin-bottom: 10px;
  }

  .main-header .user-login .nav-link span.user-name {
    font-size: 9px;
  }
}

@media (max-width: 408px) {
  /*--------------Switch-Account-section---------------------*/
  .switch-account-header {
    margin: 0;

    h2.with-abs-button {
      position: relative;

      button {
        position: absolute;
        right: 25px;
        top: 0px;
        height: 40px;
        font-size: 8px;
      }
    }
  }

  .text-nav {
    font-size: 12px !important;
  }

  .tough-title {
    font-size: 10px !important;
  }
}

@media (max-width: 380px) {
  .skip-btn {
    float: right;
  }

  .tough-title {
    font-size: 10px !important;
  }

  .made-title {
    font-size: 10px !important;
  }

  .home-orders {
    font-size: 13px !important;
  }
}

@media (max-width: 374px) {
  .home-section .section-row .section-col.left-col {
    min-width: 100%;
  }

  .content-page .section-row .section-col {
    padding-left: 0px;
  }

  .main-footer {
    width: 100% !important;

    .center-box {
      a.logo {
        display: inline-block;
      }
    }

    .footer-link {
      display: inline-block;
    }
  }

  .float-right .btn .common-button .view .btn-link .remove-style {
    right: 0px !important;
  }

  .modal-text {
    font-size: 10px !important;
  }
}

@media (max-width: 356px) {
  /*--------------Switch-Account-section---------------------*/
  .switch-account-header {
    margin: 0;

    h2.with-abs-button {
      position: relative;

      button {
        position: absolute;
        left: 80px;
        top: 45px;
        height: 35px;
        font-size: 8px;
      }
    }
  }

  .text-nav {
    font-size: 12px !important;
  }

  .tough-title {
    font-size: 9px !important;
  }

  .float-right .btn .common-button .view .btn-link .remove-style {
    right: 0px !important;
  }
}

@media (max-width: 350px) {
  .text-nav {
    font-size: 40px !important;
  }
}

@media (max-width: 345px) {
  .cat-btn {
    margin-bottom: 10px;
  }

  .modal-dialog {
    // margin-left: -13px;
  }

  .traning-client-section {
    .select-training-box {
      margin-top: 10px;
    }
  }

  .tough-title {
    font-size: 8px !important;
  }

  .made-title {
    font-size: 10px !important;
  }
}

@media (max-width: 320px) {
  .tough-title {
    font-size: 7px !important;
  }

  // .made-title{
  //   font-size: 7px !important;
  // }
}

@media (max-width: 312px) {
  .tough-title {
    font-size: 6px !important;
  }

  // .made-title{
  //   font-size: 7px !important;
  // }
  .made-title {
    font-size: 6px !important;
  }
}

@media (max-width: 294px) {
  .app-title {
    padding: 36px 0;
    // font-family: $font-boldItalic;
    // position: absolute;
    left: 100px !important;

    .navbar-brand {
      padding: 8px 0;
      // position: relative;
    }

    .tough-title {
      font-size: 6px !important;
    }

    // .made-title{
    //   font-size: 7px !important;
    // }
  }
}

@media (max-width: 286px) {
  .main-header .user-login .nav-link span.user-name {
    font-size: 11px;
  }

  .tough-title {
    font-size: 5px !important;
  }
}
