@import "appVaribles";

/*----------------common style-------------------*/
* {
  margin: 0;
  padding: 0;
}

body {
  font-family: "Lato", sans-serif !important;

  &.stuck {
    overflow: hidden;
  }
}

body::-webkit-scrollbar {
  width: 12px;
}

body::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
}

body::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #3f3f3f;
}

a {
  text-decoration: none;
  cursor: pointer;
  outline: none;

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
  }
}

button {
  outline: none;

  &:hover,
  &:focus,
  &:active {
    outline: none;
  }
}

img {
  max-width: 100%;
}

.mb-0 {
  margin-bottom: 0px !important;
}

.ml-0 {
  margin-left: 0px !important;
}

.mr-0 {
  margin-right: 0px !important;
}

.mt-0 {
  margin-top: 0px !important;
}

.line-height-normal {
  line-height: normal !important;
}

.transparent-border {
  border: 1px solid transparent !important;
}

// .font-boldItalic {
//   font-family: $font-boldItalic !important;
// }

.cust-container,
.hRnZkV {
  max-width: 1635px;
  margin: 0 auto;
}

.custom-dropdown {
  padding: 0;
  border: none;
  border-radius: 0;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.25);

  .dropdown-item.active,
  .dropdown-item:active {
    background-color: black;
    color: #3f3f3f;
    font-size: 18px;
  }
}

.section-header {
  height: 27px;
  padding: 0px;

  span {
    display: block;
    // padding: 0 57px;
    // font-family: $font-light;
    font-size: 18px;
    // text-transform: uppercase;
    color: $app-secondary-color;
    line-height: 22px;
    border-bottom: 3px solid #3f3f3f;
  }

  button {
    // font-family: $font-normal;
    font-size: 16px;
    font-weight: 400;
    height: -webkit-fill-available;
  }
}

.cust-control {
  position: relative;
  margin-bottom: 12px;

  .ctrl-inner-wrapper {
    position: relative;
  }

  input {
    height: 40px;
    border: none;
    background-color: transparent;
    border-bottom: 1px solid #000;
    border-radius: 0px;
    // font-family: $font-light;
    font-size: 14px;
    color: #000;
    outline: none;
    box-shadow: none;

    &:focus {
      background-color: transparent;
      box-shadow: none !important;
      border-bottom-color: lighten($app-primary-color, 20%);
    }

    &:invalid,
    &.is-invalid {
      background-image: url("data:image/svg+xml,%3Csvg id='warning' xmlns='http://www.w3.org/2000/svg' width='16' height='16.646' viewBox='0 0 16 16.646'%3E%3Crect id='Rectangle_582' data-name='Rectangle 582' width='16' height='16' rx='8' transform='translate(0 0.646)' fill='%23ed1b2f'/%3E%3Ctext id='_' data-name='!' transform='translate(6 13)' fill='%23fff' font-size='14' font-family='Ubuntu-Bold, Ubuntu' font-weight='700'%3E%3Ctspan x='0' y='0'%3E!%3C/tspan%3E%3C/text%3E%3C/svg%3E%0A");
      background-repeat: no-repeat;
      background-position: right center;
    }
  }

  &.with-icon {
    input {
      padding-left: 40px;
    }

    .ctrl-ico {
      position: absolute;
      font-size: 22px;
      top: 11px;
      left: 11px;
      color: $app-secondary-color;
    }
  }
}

.common-button {
  height: 40px;
  text-align: center;
  background-color: transparent;
  color: #fff;
  line-height: 40px;
  padding: 0 10px;
  // font-family: $font-bold;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 6px;
  border: none;

  &:hover,
  &:focus {
    background-color: transparent;
    box-shadow: none;
    color: #000;
  }

  &.full-width {
    width: 100%;
    min-width: auto;
  }
}

.auth-btn {
  height: 40px;
  text-align: center;
  background-color: transparent;
  color: #000;
  line-height: 40px;
  padding: 0 10px;
  // font-family: $font-bold;
  font-weight: 900;
  font-size: 16px;
  text-transform: uppercase;
  border-radius: 0px;
  border: 1px solid #000;

  &:hover,
  &:focus {
    background-color: #000;
    box-shadow: none;
    color: #fff;
  }

  &.full-width {
    width: 100%;
    min-width: auto;
  }
}

.yellow-button {
  line-height: 20px;
  font-size: 16px;
  // font-family: $font-bold;
  font-weight: 900;
  background-color: $app-secondary-color;
  color: $app-primary-color;
  min-width: auto;

  span {
    display: block;
    // font-family: $font-normal;
  }

  &:hover,
  &:focus {
    background-color: #000;
    box-shadow: none;
    color: #fff;
  }
}

.small-button {
  height: 36px;
  line-height: 36px;
  text-transform: capitalize;
  // font-family: $font-bold;
  font-weight: 900;
  font-size: 14px;
  border-radius: 2px;
  padding: 0 15px;

  &.primary-button {
    border: 1px solid #000;
    background-color: $app-primary-color;
    color: #000;

    &:hover {
      &:hover,
      &:focus {
        background-color: #000;
        box-shadow: none;
        color: #fff;
      }
    }
  }

  &.secondary-button {
    border: 1px solid $app-primary-color;
    background-color: transparent;
    color: $app-secondary-color;

    &:hover,
    &:focus {
      color: #000;
      background-color: #f3f3f3;
      box-shadow: none;
    }
  }
}

.outline-button {
  display: inline-block;
  font-weight: 400;
  color: #000;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  background-color: transparent;
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  height: 40px;
  border-radius: 2px;
  border: 1px solid #000;
  // font-family: $font-bold;

  font-weight: 900;
  font-size: 14px;

  &:hover,
  &:focus {
    color: #fff;
    background-color: #000;
    border-color: #4c4c4c;
  }

  &.with-icon {
    position: relative;
    padding-right: 50px;

    i {
      position: absolute;
      right: 0;
      top: 0;
      width: 40px;
      height: 39px;
      line-height: 40px;
      // border-left: 1px solid #000;
      border-radius: 2px;
      font-size: 24px;
    }

    &:hover {
      i {
        border-color: #4c4c4c;
      }
    }
  }

  &.icon-only {
    i {
      font-size: 24px;
    }
  }

  &.gridview-icon {
    padding-left: 95px;
    position: relative;

    &:hover,
    &:focus {
      i.iconStyle {
        span:nth-child(1),
        span:nth-child(2) {
          border-color: #fff;
        }

        span:nth-child(3),
        span:nth-child(4) {
          &:before,
          &:after {
            background-color: #fff;
          }
        }
      }
    }

    i.iconStyle {
      position: absolute;
      left: 9px;
      top: 7px;

      span {
        &:nth-child(1),
        &:nth-child(2) {
          position: absolute;
          display: inline-block;
          height: 10px;
          width: 10px;
          border: 1px solid #fff;
        }

        &:nth-child(3),
        &:nth-child(4) {
          position: absolute;

          &:before,
          &:after {
            content: "";
            display: block;
            height: 1px;
            margin-bottom: 4px;
            width: 40px;
            background-color: #fff;
          }
        }

        &:nth-child(1) {
          top: 0px;
          left: 0px;
        }

        &:nth-child(2) {
          top: 15px;
          left: 0px;
        }

        &:nth-child(3) {
          top: 2px;
          left: 18px;
        }

        &:nth-child(4) {
          top: 17px;
          left: 18px;
        }
      }
    }
  }

  &.listview-icon {
    padding-left: 95px;
    position: relative;

    &:hover,
    &:focus {
      i.iconStyle {
        span {
          background-color: #fff;
        }
      }
    }

    i.iconStyle {
      position: absolute;
      left: 9px;
      top: 8px;

      span {
        display: block;
        height: 1px;
        background: #fff;
        width: 57px;
        margin-bottom: 4px;

        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

.outline-select {
  height: 40px;
  border-radius: 2px;
  border: 1px solid #000;
  // font-family: $font-bold;

  font-weight: 900;
  font-size: 14px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  font-weight: 400;
  padding: 0.375rem 0.75rem;
}

.form-control:focus {
  border-color: $app-primary-color;
}

.form-group {
  & > label {
    margin-bottom: 3px;
  }

  .form-control {
    height: 40px;
    border-radius: 0px;

    &:focus {
      box-shadow: none;
      border-color: $app-primary-color;
    }
  }

  .ctrl-inner-wrapper {
    .form-control {
      height: 40px;
      border-radius: 0px;

      &:focus {
        box-shadow: none;
        border-color: $app-secondary-color;
      }
    }
  }

  textarea.form-control {
    min-height: 95px;
  }

  .rdw-editor-toolbar {
    margin-bottom: 0px;
  }

  .rdw-editor-main {
    border: 1px solid #f1f1f1;
    padding: 5px;
    max-height: 1000px !important;
    resize: vertical;
  }
}

.react-toggle {
  &:active:not(.react-toggle--disabled),
  &.react-toggle--focus {
    .react-toggle-thumb {
      box-shadow: none !important;
      background-color: #e1e0d6;
    }
  }
}

p.note-text {
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;

  a {
    color: #000;
    text-decoration: underline;
  }
}

.grid-searchbar {
  height: 50px;
  position: relative;
  background-color: #d3d3d3;
  border-radius: 5px;
  overflow: hidden;
  margin-bottom: 10px;

  input {
    height: 100%;
    padding: 0 12px;
    color: $app-primary-color;
    line-height: 50px;
    display: block;
    width: 100%;
    background: transparent;
    padding-right: 50px;
  }

  .form-control {
    &:focus {
      border: 1px solid #3f3f3f;
    }
  }

  i {
    position: absolute;
    right: 15px;
    top: 17px;
    font-size: 20px;
    color: #000;
  }
}

.grid-header-actions {
  @extend %display-flex;
  margin-bottom: 10px;

  .right-box {
    margin-left: auto;

    .outline-button,
    .outline-select {
      background: $app-secondary-color;
      color: #fff;
      border: 0px !important;
      margin-right: 10px;
      margin-bottom: 10px;
    }
  }

  p.note-text {
    height: 40px;
    padding-top: 10px;
  }
}

.footer-button-box {
  text-align: center;
  padding-bottom: 59px;

  button {
    width: 287px;
  }
}

.action-button {
  display: inline-block;
  border: none;
  outline: none;
  box-shadow: none;
  height: 30px;
  width: 30px;
  background: $app-secondary-color;
  color: #fff;
  font-size: 16px;
  line-height: 30px;
  border-radius: 3px;
  @extend %common-transition;
  margin-right: 10px;

  svg {
    margin-top: -7px;
  }

  &:hover {
    background-color: #000;
    color: #fff;
  }

  &:last-child {
    margin-right: 0px;
  }
}

.app-chk {
  position: relative;
  cursor: pointer;
  line-height: normal;
  margin: 0;
  padding: 0;
  align-items: center;

  input {
    opacity: 0;
    position: absolute;
    z-index: 1;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    cursor: pointer;
    display: block;

    & ~ .chk-style {
      &:before {
        opacity: 0;
      }
    }

    &:checked ~ .chk-style {
      &::before {
        opacity: 1;
      }
    }

    &:checked ~ .chk-style {
      &::after {
        opacity: 0;
      }
    }

    &[type="checkbox"] ~ .chk-style {
      &:before {
        content: "";
        content: "";
        display: inline-block;
        position: absolute;
        height: 7px;
        width: 12px;
        border-width: 0 0 2px 2px;
        border-style: solid;
        border-color: #000;
        left: 0;
        right: 0;
        margin: auto;
        top: 50%;
        margin-top: -6px;
        -khtml-transform: rotate(-45deg);
        transform: rotate(-45deg);
      }

      // &:after {
      //   content: "";
      //   display: inline-block;
      //   position: absolute;
      //   height: 7px;
      //   width: 12px;
      //   border-width: 0 0 2px 2px;
      //   border-style: solid;
      //   border-color: #000;
      //   left: 0;
      //   right: 0;
      //   margin: auto;
      //   top: 50%;
      //   margin-top: -6px;
      //   @include css3-prefix(transform, rotate(-45deg));
      // }
    }

    &[type="radio"] ~ .chk-style {
      &:before {
        content: "";
        display: inline-block;
        position: absolute;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        background-color: $app-secondary-color;
        left: 0;
        right: 0;
        margin: auto;
        top: 38%;
        margin-top: -5px;
      }
    }

    &[type="radio"] ~ .chk-style {
      &:after {
        content: "";
        display: inline-block;
        position: absolute;
        height: 15px;
        width: 15px;
        border-radius: 50%;
        border: 1px solid black;
        left: 0;
        right: 0;
        margin: auto;
        top: 38%;
        margin-top: -5px;
      }
    }

    &[disabled] {
      opacity: 0 !important;

      & ~ .chk-style {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }

  .chk-style {
    display: inline-block;
    vertical-align: middle;
    height: 23px;
    width: 23px;
    border-radius: 50%;
    border: 1px solid #000;
    position: relative;
  }

  .chk-text {
    margin-left: 10px;
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    color: #000;
  }

  &.innerMenu-chk {
    .chk-style {
      position: absolute;
      left: auto;
      top: 4px;
      right: -31px;
    }

    .chk-text {
      // margin-left: 35px;
    }
  }
}

.cust-radio-group {
  label.app-chk {
    margin-right: 20px;
    align-items: center;

    .chk-text {
      margin-left: 10px;
    }

    &:last-child {
      margin-right: 0px;
    }
  }
}

.clear-button {
  font-size: 14px;
  // font-family: $font-bold;
  font-weight: 900;

  &:hover,
  &:focus {
    opacity: 0.8;
    outline: none;
  }
}

.element-group {
  display: inline-flex;
  width: auto;

  input {
    width: 97%;
    // height: 30px;
    background-color: transparent;
    border: 2px solid #b2b2b2;
    padding: 11px 14px;
    font-size: 16px;
    color: #000;
    outline: none;
    @extend %common-transition;

    &:focus {
      border-color: $app-secondary-color;
    }

    &:invalid {
      box-shadow: none;
    }

    & + button {
      margin-left: -1px;
    }
  }
}

.note-text {
  .note-title {
    font-size: 22px;
    // font-family: $font-bold;
    font-weight: 900;
    color: $app-secondary-color;
    line-height: 19px;
    margin-bottom: 10px;
  }

  .note-desc {
    font-size: 14px;
    color: #000;
    line-height: 16px;
    margin-bottom: 0px;
  }
}

.react-dropdown-tree-select {
  .dropdown {
    width: 100%;

    .dropdown-trigger {
      border-bottom: none;
      border-radius: 0;
      width: 100%;
      padding: 0px !important;

      ul.tag-list {
        li.tag-item {
          span.tag {
            padding: 2px 16px 2px 5px;
            font-size: 14px;
            border-radius: 0;
            position: relative;
            color: #000;

            &:hover {
              color: #000;
            }

            .tag-remove {
              position: absolute;
              right: 3px;
              top: 4px;
              font-size: 15px;
              color: #666;

              &:hover {
                color: lighten($app-primary-color, 20%) !important;
              }
            }
          }
        }

        li.tag-item:last-child {
          min-width: 100%;
          margin: 0;

          input {
            // font-family: $font-normal;
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.5;
            color: #495057;
            padding: 0.375rem 0.75rem;
            width: 100%;
          }
        }
      }

      &.arrow.top,
      &.arrow.bottom {
        &:after {
          position: absolute;
          right: 7px;
          bottom: 17px;
        }
      }
    }

    .dropdown-content {
      min-width: 200px;

      .infinite-scroll-component {
        max-height: 210px;
      }

      ul.root {
        li.node {
          label {
            margin-bottom: 0px;
          }

          input[type="checkbox"] {
            height: 14px;
            display: inline-block;
            vertical-align: middle;
            margin-right: 7px;
            width: 14px;
          }

          span {
            display: inline-block;
            vertical-align: middle;
          }

          i {
            outline: none;

            &:after {
              font-family: "lasher-icons" !important;
              speak: never;
              font-style: normal;
              font-weight: normal;
              font-feature-settings: normal;
              font-variant: normal;
              text-transform: none;
              line-height: 1;
              font-size: 15px;
              display: inline-block;
              vertical-align: middle;
              color: #000;
            }

            &.collapsed:after {
              content: "\e912";
            }

            &.expanded:after {
              content: "\e917";
            }
          }
        }
      }
    }
  }
}

/*-----------app-grid-box------------------*/
.app-grid-box {
  .app-grid-scroll-container {
    overflow-x: auto;
  }

  .app-grid {
    width: 100%;

    thead {
      th {
        background-color: #3f3f3f;
        height: 60px;
        vertical-align: middle;
        // font-family: $font-bold;
        font-weight: 900;
        font-weight: normal;
        font-size: 14px;
        color: #fff;
        border: none;
        padding: 5px 8px;

        &.action-col {
          text-align: center;
        }
      }
    }

    tbody {
      tr {
        height: 50px;

        &:hover {
          background-color: #f7f7f7;
        }

        td,
        th {
          border: none;
          // font-family: $font-normal;
          font-size: 14px;
          color: #000;
          vertical-align: middle;
          padding: 12px 8px;

          &.action-col {
            padding-top: 0px;
            padding-bottom: 0px;
            text-align: center;
          }

          .react-toggle {
            vertical-align: middle;
          }
        }
      }
    }

    &.with-border {
      border-left: 1px solid #000;
      border-right: 1px solid #000;
      border-top: 1px solid #000;

      th,
      td {
        border-bottom: 1px solid #000;
      }
    }

    //------ common cols type-----
    .sr-col {
      max-width: 45px;
    }

    .name-col,
    .email-col {
      min-width: 90px;
    }

    .phone-col {
      min-width: 100px;
    }

    .action-col {
      min-width: 140px;
    }
  }
}

/*-----------app-modal----------------------*/
.app-modal {
  .modal-content {
    border-radius: 2px;

    .modal-header {
      padding: 10px 15px;

      .modal-title {
        color: $app-secondary-color;
        font-size: 22px;
      }

      .close {
        font-size: 30px;
        height: 54px;
        line-height: 54px;
        margin: 0;
        position: absolute;
        right: 0;
        top: 0;
        padding: 0 15px;
        background-color: white;
        border: 0;

        &:hover {
          color: #000;
        }
      }
    }

    .modal-footer {
      padding: 3px 15px;
    }
  }
}

/*-------sweet loader----------*/
.sweet-loading {
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9;

  .css-198xogd {
    width: 100px;
    height: 100px;
  }
}

/*-----form-container----------*/
.form-container {
  max-width: 798px;
  margin: 0 auto;
  padding: 0 15px;
}

/*-------add edit form-----------*/
.addEdit-form {
  padding-bottom: 20px;

  .btn-box {
    @extend %display-flex;
    margin: 10px -5px 0 -5px;

    button {
      margin: 0 5px;
    }
  }
}

/* side bar*/
.with-sidebar {
  @extend %display-flex;

  .sidebar-wrapper {
    min-width: 392px;
    width: 392px;
    max-width: 392px;
    margin-right: 60px;
    padding-top: 52px;
  }

  .sidebar-content {
    flex: 1;
    @include calc(max-width, "100% - 392px - 22px");
    padding-bottom: 20px;
  }
}

.side-menu-nav {
  border-top: 1px solid #000;
  background-color: #d3d3d3;

  li {
    .nav-link {
      border-bottom: 1px solid #000;
      // font-family: $font-normal;
      font-size: 20px;
      color: #000;
      height: 60px;
      font-weight: 700;
      line-height: 44px;
      padding-left: 15px;
      align-items: center;
      @extend %common-transition;

      &:hover,
      &.active {
        color: $app-secondary-color;
      }
    }
  }
}

.side-menu {
  .accordian-header {
    height: 60px;
    border-top: 1px solid #000;
    color: $app-secondary-color;
    // font-family: $font-bold;
    font-weight: 900;
    font-size: 20px;
    line-height: 60px;

    // text-transform: uppercase;
    input {
      // font-family: $font-normal !important;
    }
  }

  .accordion {
    .card-body {
      padding: 10px 34px;
    }

    .card {
      border: none;
      background: none;
      margin-top: -1px;

      .card-header {
        background: none;
        height: 60px;
        border-top: 1px solid #000;
        border-bottom: 1px solid #000;
        // font-family: $font-bold;
        font-weight: 900;
        font-size: 20px;
        line-height: 60px;
        padding: 0px;
        position: relative;
        cursor: pointer;
        border-radius: 0 !important;

        img {
          position: absolute;
          right: 20px;
          top: 50%;
          font-size: 12px;
          margin-top: -6px;
          color: #000;
          @extend %common-transition;
        }
      }

      .collapsed {
        .card-header {
          img {
            @include css3-prefix(transform, rotate(90deg));
          }
        }
      }
    }
  }
}

/*----------add remove btns------------*/
.add-remove-btn {
  svg {
    height: 40px;
    width: 40px;
    cursor: pointer;
    margin-right: 5px;
  }
}

.with-add-remove-btns {
  padding-right: 100px;
  position: relative;
  margin-bottom: 5px;

  .add-remove-btn {
    position: absolute;
    top: 0;
    right: 0;
  }
}

/*-----------custom toolpick css------*/
.bs-popover-bottom > .arrow::after,
.bs-popover-auto[x-placement^="bottom"] > .arrow::after {
  border-bottom-color: $app-secondary-color;
  border-radius: 0%;
}

#tawkchat-status-text-container.theme-background-color {
  background-color: $app-secondary-color !important;
}

.filter-label {
  color: $app-secondary-color !important;
  font-size: 22px !important;
}

.backorders {
  margin-bottom: 40px;
}

.addnew-btn {
  color: #3f3f3f !important;
  background: #fff !important;
}

.common-select {
  .select__control {
    border-bottom: 1px solid black !important;
    border: none;
    border-radius: 0px;

    .select__value-container {
      padding-left: 0px;

      .select__placeholder {
        color: #000;
      }
    }

    .select__indicators {
      .select__indicator {
        color: #000;
      }

      .select__indicator-separator {
        width: 0px !important;
      }
    }
  }

  .select__control--is-focused {
    // border-bottom: 1px solid black !important;
    border-width: 0 !important;
    outline: none !important;
  }
}

.user-dropdown {
  margin: 0px !important;
  transform: translate3d(199px, 68px, 0px) !important;

  a {
    &:hover {
      color: $app-secondary-color !important;
    }
  }
}

.icon-plus {
  font-size: x-large;
  font-weight: bold !important;
}

.form-control.main-select {
  border: none !important;
  border-bottom: 1px solid #000 !important;
}

.filter-input {
  position: relative;
  margin-bottom: 28px;

  input {
    border-radius: 0px !important;
    border: 2px solid #b2b2b2;
    height: 50px !important;

    &:focus {
      box-shadow: none !important;
      border: 2px solid rgb(194, 32, 28);
    }
  }

  .icon-search {
    position: absolute;
    right: 0px;
    top: 0px;
    color: white;
    background: $app-secondary-color;
    padding: 15px 10px;
  }
}

.filter-search {
  border-bottom: 1px solid black;
}

.brand-menu {
  .chk-style {
    border-radius: 5px !important;
  }

  .app-chk {
    align-items: center;

    .chk-style {
      border-radius: 5px !important;
    }

    input[type="checkbox"] ~ .chk-style {
      &:after {
        opacity: 0;
      }
    }
  }
}

.breadcrumb {
  background: transparent;
  margin-bottom: 2px;
  line-height: 20px;
  width: max-content;

  &:not(:last-child) {
    &:after {
      content: "\003E";
      padding-left: 10px;
      color: #d3d3d3;
    }
  }
}

.subcategory-div {
  // background: $app-secondary-color;
  margin-bottom: 20px;

  .inner-div {
    padding: 10px 20px;
    background: $app-secondary-color;
    color: #fff;
    align-items: center;
    span {
      font-size: 12px;
      width: 100px;
    }

    label {
      margin: 0px;
      font-weight: 700;
      text-transform: uppercase;
    }
  }

  .sub-lists {
    padding: 3.27px;
    border: 2px solid #b2b2b2;
    border-left: 0px !important;

    .kVtMtA,
    .bhRnqO {
      box-shadow: none;
      background: transparent !important;
      height: 36px !important;
      &:before {
        border-top: 2px solid #3f3f3f !important;
        border-right: 2px solid #3f3f3f !important;
      }
    }

    ul {
      flex-wrap: wrap;

      li {
        &:not(:last-child) {
          &:after {
            content: "\007C";
            padding-left: 10px;
            color: #3f3f3f;
          }
        }
      }

      margin: 0px;
      color: #3f3f3f;
      font-weight: 700;
    }
  }
}

.search-nav {
  width: calc(100% - 24px);

  .header-search {
    .header-search-box {
      border: 2px solid #b2b2b2;
      margin-bottom: 20px !important;
    }

    input {
      height: 45px !important;
      padding: 8px 0 8px 12px !important;
      background-color: #f0f0f0 !important;
      color: #3f3f3f !important;
    }

    div {
      background-color: #d3d3d3;

      .search-toggle {
        position: relative !important;
        width: 150px !important;
        right: 0px !important;
        border: 0px !important;

        option {
          span {
            &:after {
              content: "\e900";
            }
          }
        }
      }
    }

    .react-autosuggest__suggestions-container {
      margin: 0px !important;
    }
  }
}

.cart-section {
  .outline-button {
    background: $app-secondary-color !important;
    color: $app-primary-color !important;
    border: 0px !important;
  }
}

.cart-form {
  text-align: -webkit-center;

  // text-align: center;
  .labels {
    color: #000000;
    font-weight: 700;
  }

  .cart-box {
    margin-top: 20px;
    width: 695px;
    border: 1px solid #b2b2b2;

    .payment-options {
      display: flex;
      justify-content: center;

      .payment-chk {
        .chk-style {
          color: $app-secondary-color !important;

          img {
            height: 78px;
          }
        }
      }
    }
  }

  .cust-control.with-icon {
    input {
      padding-left: 0px !important;
      color: #000;
    }
  }
}

.contactus {
  .contact-header {
    text-align: center;
    margin-bottom: 70px;

    h2 {
      font-size: 45px;
      text-transform: uppercase;
      color: #b2b2b2;
    }

    label {
      color: $app-secondary-color;
      font-size: 24px;
      text-transform: uppercase;
      font-weight: 700;
    }
  }

  .contact-body {
    h3 {
      color: #000000;
      font-weight: 700;
      font-size: 27px;
      text-align: left;
      text-transform: uppercase;
      margin-bottom: 20px;
    }

    .contact-details {
      max-width: 400px;

      .row {
        margin-bottom: 33px;

        .icon-div {
          font-size: 20px;

          img {
            max-width: fit-content;
          }

          .icon-map-marker {
            font-size: 24px;
          }
        }

        .detail-div {
          padding-left: 28px !important;
        }

        span {
          font-weight: 400;
          color: #000000;
          text-align: left;
          font-size: 16px;
        }
      }

      div.hours {
        p {
          color: $app-secondary-color !important;
          font-weight: 700;
          font-size: 18px;
          text-transform: uppercase;
          margin-bottom: 8px;
          line-height: 18px;
        }

        ul {
          li {
            list-style: none;
            color: #000000;
            line-height: 30px;
          }
        }
      }
    }
  }

  .form {
    .form-group {
      margin-bottom: 33px !important;
    }

    input,
    select,
    textarea {
      border: 1px solid #3f3f3f;
    }

    select {
      color: #3f3f3f;
    }
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #3f3f3f;
    font-weight: 400 !important;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #3f3f3f;
    font-weight: 400 !important;
  }

  ::placeholder {
    color: #3f3f3f;
    font-weight: 400 !important;
  }
}

.contact-btn {
  .btn {
    padding: 0 78.77px;
  }
}

// .table-responsive {
//   display: table !important;
// }
.icon-down-arrow {
  font-weight: 900 !important;
  color: #000;
  transform: rotate(90deg) !important;
  font-size: 18px !important;
}

.price-card.card {
  .card-header {
    border-bottom: 0px !important;
  }

  .card-body {
    padding-top: 0px !important;
  }

  .to {
    color: #b2b2b2;
  }
}

.dropdown-menu {
  border-radius: 0px !important;
}
.set-padding {
  padding-top: 40px;
}
.margin {
  margin: 0 5% !important;
}
